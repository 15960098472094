import React from 'react'
import {
    returnCardInformation,
    raiseSelectItemEvent,
    DataLayerEventName,
    CollectionEvent,
    toTopicRouteHint,
} from '@news-mono/web-common'
import {
    StyledHeader,
    StyledBadgeSpan,
    StyledHeaderAnchor,
    StyledHeadline,
    StyledTopicAnchor,
    StyledHeaderMeta,
    StyledKicker,
} from './PNArticleCardHeader.styled'
import { PNArticleCardProps } from '../../PNArticleCard/PNArticleCard'
import { IconZap } from '../../../../../icons/IconZap/IconZap'
import { getFeatureEditorialType } from '../../../../../templates'

/**
 * Renders the header component for the PNArticleCard.
 *
 * @component
 * @param {PNArticleCardProps} props - The props for the PNArticleCardHeader component.
 * @returns {React.ReactElement | null} The rendered header component.
 */
export const PNArticleCardHeader: React.FC<PNArticleCardProps> = (props) => {
    const {
        item,
        onEvent,
        cardType,
        imageMode = 'right',
        fontScale = 'M',
        cardNumber,
        kickerMode = 'middle',
        headerFontScale = fontScale,
        showTopic = true,
        headerSectionTag = 'h2',
        headerFontOverride,
    } = props

    // Check if the item is loaded
    if (item.loaded) {
        const { result } = item
        const { shortHeadline = '', link, classification } = result
        const cardInfo = returnCardInformation(result)

        // Helper that fires the select_item list event
        const linkClicked = () => {
            raiseSelectItemEvent(
                onEvent,
                'pn-article-card-headline',
                cardInfo,
                result,
                cardNumber,
            )
        }

        // Helper that fires the nav.clicked event
        const onTopicClick = () => {
            onEvent({
                type: DataLayerEventName.navClicked,
                originator: 'HeaderLink',
                payload: {
                    navName: 'Default.PerthNow',
                    navLocation: 'Footer',
                    navText: result.primaryTopic?.title,
                    navLink: result.primaryTopic?.id,
                    navPath: result.primaryTopic?.title,
                },
            } as unknown as CollectionEvent)
        }

        const classificationLabel = getFeatureEditorialType(
            result.classification,
            true,
        )

        return (
            <StyledHeader
                tabIndex={-1}
                data-card-type={cardType}
                data-image-mode={imageMode}
                data-font-scale={fontScale}
            >
                {(classification || showTopic) && (
                    <StyledHeaderMeta fontScale={fontScale}>
                        {classification && (
                            <StyledBadgeSpan
                                aria-label={`${classification?.label} tag`}
                            >
                                {classification.label.toLowerCase() ===
                                    'breaking' && <IconZap />}
                                {classificationLabel}
                            </StyledBadgeSpan>
                        )}
                        {showTopic && (
                            <StyledTopicAnchor
                                to={toTopicRouteHint(result.primaryTopic)}
                                aria-label={result.primaryTopic?.title}
                                tabIndex={0}
                                onClick={onTopicClick}
                            >
                                {result.primaryTopic?.title}
                            </StyledTopicAnchor>
                        )}
                    </StyledHeaderMeta>
                )}

                {kickerMode === 'middle' && result.kicker && (
                    <StyledKicker>{result.kicker}</StyledKicker>
                )}

                <StyledHeaderAnchor to={link} onClick={linkClicked}>
                    <StyledHeadline
                        as={headerSectionTag}
                        id={shortHeadline}
                        data-font-scale={headerFontScale}
                        headerFontOverride={headerFontOverride}
                    >
                        {shortHeadline}
                    </StyledHeadline>
                </StyledHeaderAnchor>
            </StyledHeader>
        )
    }

    // Return null if the item is not loaded
    return null
}
