import { TheWestSection } from '@news-mono/common'
import {
    calcRem,
    metrics,
    theWestTextOnlySectionHeaderOverride,
} from '@news-mono/component-library'
import {
    Election,
    ElectionDefinition,
    getElectionId,
    getElectionToggles,
    GetRouteAdTargeting,
    getSocialImageMeta,
    PageType,
    TogglesReduxState,
    Topic,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'

type CreateElectionPageArgs = {
    getAdTargeting: GetRouteAdTargeting
    electionDefinition: ElectionDefinition
    toggles: TogglesReduxState
    heading?: string
    description?: string
}

export function getElection2025Topic(election: Election) {
    //heading with either be "Federal" or the state
    const topicHeading =
        election.type === 'state' ? election.state.toUpperCase() : 'Federal'
    return {
        title: `${topicHeading} Election ${election.year}`,
        id: `politics/${topicHeading.toLowerCase}-election-${election.year}`,
        seoTitle: `${topicHeading} Election ${election.year}`,
        metadata: {},
        parent: {
            title: 'Politics',
            id: 'politics',
            seoTitle: 'Politics',
            metadata: {},
        },
    }
}

export const createElectionLandingPage = ({
    getAdTargeting,
    electionDefinition: { election, logo, meta },
    toggles,
    heading,
    description,
}: CreateElectionPageArgs): PageType<TheWestSection> => {
    const {
        'election-landing-page-the-race': isTheRaceEnabled,
        'election-landing-page-the-seats': isTheSeatsEnabled,
    } = getElectionToggles(election, toggles)

    let collectionHeading

    if (election.type === 'federal') {
        heading ??= `${election.year} Federal Election`
        description ??= `Latest Updates for the ${heading}`
        collectionHeading = 'Latest Federal Election News'
    } else {
        heading ??= `${
            election.year
        } ${election.state.toUpperCase()} State Election`
        description ??= `Latest Updates for the ${heading}`
        collectionHeading = `Latest ${election.state.toUpperCase()} Election News`
    }

    return {
        kind: 'page',
        heading,
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting: getAdTargeting(
            'home',
            'default',
            getElection2025Topic(election),
        ),
        pageMeta: {
            title: heading,
            description,
            meta: meta.updatesSocialImage
                ? getSocialImageMeta(meta.updatesSocialImage, '2400', '1260')
                : undefined,
        },
        socialMeta: {
            title: heading,
            description,
        },
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    flex: {
                        default: {
                            flexDirection: 'column',
                            gap: calcRem(24),
                        },
                        sm: {
                            gap: calcRem(48),
                        },
                    },
                    verticalGutters: ['unset', 'xxl'],
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'breaking-news',
                                        props: {
                                            dataDefinitionArgs: {
                                                type: 'curation',
                                                name: 'breaking-news',
                                                offset: 0,
                                                pageSize: 1,
                                            },
                                            isElectionPageRoute: true,
                                            removeBottomMargin: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'election-navigation',
                                        props: {
                                            election,
                                            electionLogo: logo,
                                            activePage: 'updates',
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                horizontalSpacing: 'outerMargin',
                                flex: {
                                    default: {
                                        flexDirection: 'column',
                                        gap: calcRem(24),
                                    },
                                    lg: {
                                        gap: calcRem(48),
                                    },
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'component-overrider',
                                        props: {
                                            override: () =>
                                                theWestTextOnlySectionHeaderOverride,
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.component({
                                                    type: 'sierra',
                                                    props: {
                                                        cardLayout: [
                                                            'the-west-hero',
                                                            {
                                                                type: 'november',
                                                                listLength: 4,
                                                                hasBackground:
                                                                    false, //So that the border appears on the last item ¯\_(ツ)_/¯
                                                                paddingOverride:
                                                                    calcRem(
                                                                        16,
                                                                        0,
                                                                        0,
                                                                        0,
                                                                    ),
                                                            },
                                                        ],
                                                        isLarge: true,
                                                        heroBottomPadding: 8,
                                                        dataDefinitionArgs: {
                                                            type: 'curation',
                                                            // !! Warning !! Election curations will need to be created for every future election, or the page will resolve to 'Not Found'.
                                                            name: getElectionId(
                                                                election,
                                                            ),
                                                            pageSize: 5,
                                                            offset: 0,
                                                        },
                                                        gridGap: 24,
                                                        breakpoints: {
                                                            lg: {
                                                                gridGap: 32,
                                                            },
                                                        },
                                                        sectionHeader: {
                                                            heading: 'Updates',
                                                            isStandalonePage:
                                                                true,
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    ...(isTheRaceEnabled
                                        ? [
                                              layout.component({
                                                  type: 'election-the-race-widget',
                                                  props: {
                                                      election,
                                                      dataDefinitionArgs: {
                                                          type: 'election',
                                                          electionId: 632,
                                                          pageSize: 1,
                                                      },
                                                  },
                                              }),
                                          ]
                                        : []),
                                    ...(isTheSeatsEnabled
                                        ? [
                                              layout.component({
                                                  type: 'election-seat-widget',
                                                  toggle:
                                                      election.type === 'state'
                                                          ? 'wa-election-landing-page-the-seats'
                                                          : 'federal-election-landing-page-the-seats',
                                                  props: {
                                                      dataDefinitionArgs: {
                                                          type: 'election',
                                                          electionId: 632,
                                                          pageSize: 1, // Throwaway value, required to pass type check ¯\_(ツ)_/¯
                                                      },
                                                      electoratePageUrl: `/politics/${getElectionId(
                                                          election,
                                                      )}/electorates`,
                                                  },
                                              }),
                                          ]
                                        : []),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {},
                                        contentAreas: {
                                            main: billboardLeaderboardMrec(
                                                'one',
                                                0,
                                            ),
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {},
                                        contentAreas: {
                                            main: [
                                                layout.nestedComposition({
                                                    type: 'component-overrider',
                                                    props: {
                                                        override: () =>
                                                            theWestTextOnlySectionHeaderOverride,
                                                    },
                                                    contentAreas: {
                                                        children: [
                                                            layout.component({
                                                                type: 'uniform',
                                                                props: {
                                                                    cardLayout:
                                                                        [
                                                                            {
                                                                                type: 'november',
                                                                                listLength: 5,
                                                                            },
                                                                            [
                                                                                'large-card',
                                                                            ],
                                                                            [
                                                                                'large-card-mdNoImage',
                                                                                'large-card-mdNoImage',
                                                                            ],
                                                                            [
                                                                                'large-card-mdNoImage',
                                                                                'large-card-mdNoImage',
                                                                            ],
                                                                        ],
                                                                    section:
                                                                        'default',
                                                                    sectionHeader:
                                                                        {
                                                                            heading:
                                                                                collectionHeading,
                                                                        },
                                                                    dataDefinitionArgs:
                                                                        {
                                                                            type: 'listing',
                                                                            topics: [
                                                                                `politics/${getElectionId(
                                                                                    election,
                                                                                )}`,
                                                                            ],
                                                                            includeSubTopics:
                                                                                true,
                                                                            publicationKind:
                                                                                'article',
                                                                            paging: {
                                                                                page: 1,
                                                                                pageSize: 10,
                                                                            },
                                                                        },
                                                                    hasBorder:
                                                                        false,
                                                                },
                                                            }),
                                                        ],
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'lima',
                                                    props: {
                                                        verticalSpacing: 'md',
                                                        fontScale: 1.2,
                                                        fixedRatios: ['16:9'],
                                                        hasBackground: true,
                                                        initialColumns: 1,
                                                        intermediateColumns: 2,
                                                        finalColumns: 4,

                                                        loadMoreEnabled: true,
                                                        dataDefinitionArgs: {
                                                            type: 'listing',
                                                            topics: [
                                                                `politics/${getElectionId(
                                                                    election,
                                                                )}`,
                                                            ],
                                                            includeSubTopics:
                                                                true,
                                                            publicationKind:
                                                                'article',
                                                            paging: {
                                                                page: 1,
                                                                pageOffset: 10,
                                                                pageSize: 8,
                                                            },
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {},
                                        contentAreas: {
                                            main: billboardLeaderboardMrec(
                                                'two',
                                                0,
                                            ),
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
