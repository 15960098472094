import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { StaticRoutes, TogglesReduxState } from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'
import { billboardLeaderboardMrec } from '../ad-units'
import { aflSegment } from './afl-segment'
import { doubleSportSegment } from './double-sport-segment'
import { bestAustralianYarnSegment } from './best-australian-yarn-segment'
import { brandContentSegment } from './brand-content-segment'
import { businessSegment } from './business-segment'
import { climateChangeSegment } from './climate-change-segment'
import { commonwealthGamesSegment } from './commonwealth-games-segment'
import { courtsCrimeSegment } from './courts_crime'
import { developingNewsSegment } from './developing-news-segment'
import { educationSegment } from './education-segment'
import { entertaimentAndLifestyleSegment } from './entertainment-lifestyle-segment'
import { foodAndDrinkSegment } from './food-and-drink-segment'
import { getThemedSegment } from './get-themed-segment'
import { grandFinalSegment } from './grand-final-segment'
import { healthSegment } from './health-segment'
import {
    breakingNews,
    homeSegment,
    postHomeSegment,
    preHomeSegment,
    promoStrap,
} from './home-segment'
import { inCaseYouMissedItSegment } from './in-case-you-missed-it-segment'
import { indigenousAffairsSegment } from './indigenous-affairs'
import { miscCollectionsSegment } from './misc-collections-segment'
import { moreSportSegment } from './more-sport-segment'
import { opinionSegment } from './opinion-segment'
import { politicsSegment } from './politics-segment'
import { propertySegment } from './property-segment'
import { regionalSegment } from './regional-segment'
import { subscriberExclusive } from './subscriber-exclusive'
import { travelSegment } from './travel-segment'
import { upLateSegment } from './up-late-segment'
import { videoHubSegment } from './video-hub-segment'
import { webStoriesSegment } from './web-stories-segment'
import { worldSegment } from './world-segment'
import { yourMoneySegment } from './your-money-segment'
import { telethonCountdown } from './telethon-countdown'

export const rampAdUnit = layout.component({
    type: 'ad-unit',
    props: {
        noticePosition: 'below-center',
        hiddenUntilLoaded: true,
        padding: [0, 0, metrics.thewest.margins.lg, 0],
        slot: {
            id: 'ramp',
            size: 'ramp',
            exclusionLabels: ['NoAds'],
        },
        adType: 'inline',
    },
})

export const homepage: StaticRoutes<TheWestSection> = {
    '': ({ getAdTargeting, store }) => {
        const pageMetaDescription =
            'The West Australian is a leading news source in Perth and WA. Breaking local and world news from sport and business to lifestyle and current affairs.'
        const { adUnitPath } = getAdTargeting('home', 'default', 'home')

        return {
            kind: 'page',
            heading: 'The West Australian | Perth, WA, National & World News',
            pageType: 'homepage',
            hideHeading: true,
            refreshOnInactive: true,
            noMetaTitleTemplate: true,
            pageMeta: {
                title: 'The West Australian | Perth, WA, National & World News',
                description: pageMetaDescription,
                meta: [
                    {
                        property: 'og:description',
                        content: pageMetaDescription,
                    },
                    {
                        property: 'twitter:description',
                        content: pageMetaDescription,
                    },
                ],
            },
            adTargeting: getAdTargeting('home', 'default', 'home'),
            section: 'default',

            compositions: [
                developingNewsSegment(),
                mainContentSection(store.getState().toggles, [
                    grandFinalSegment(store.getState().toggles),
                    commonwealthGamesSegment(),
                    telethonCountdown,
                    courtsCrimeSegment,
                    getThemedSegment({
                        featureToggle: '190-years-of-the-west',
                        layoutVersion: '4-card',
                        curation: '190-years-of-the-west',
                        themeOverride: '190-years-of-the-west',
                        cardExtensionProps: {
                            fourCardExtension: {
                                thorProps: {
                                    hasBackgroundFill: false,
                                    unsetFeatureContainerBackground: true,
                                },
                                kind: '4-card',
                            },
                        },
                    }),
                    politicsSegment(store.getState().render.renditionType),
                    ...videoHubSegment(adUnitPath),
                    ...billboardLeaderboardMrec('one'),
                    inCaseYouMissedItSegment(),
                    ...businessSegment(store),
                    bestAustralianYarnSegment({ isGenwest: false }),
                    subscriberExclusive(),
                    ...billboardLeaderboardMrec('two'),
                    ...upLateSegment,
                    webStoriesSegment(),
                    educationSegment,
                    rampAdUnit,
                    healthSegment,
                    entertaimentAndLifestyleSegment,
                    ...aflSegment,
                    doubleSportSegment,
                    moreSportSegment,
                    indigenousAffairsSegment,
                    ...billboardLeaderboardMrec('three'),
                    opinionSegment,
                    climateChangeSegment,
                    worldSegment,
                    yourMoneySegment,
                    propertySegment,
                    foodAndDrinkSegment,
                    ...brandContentSegment,
                    regionalSegment,
                    ...travelSegment,
                    miscCollectionsSegment,
                ]),
                layout.composition({
                    type: 'thor',
                    props: {
                        hasBackgroundFill: false,
                        containerWidth:
                            metrics.thewest.siteMetrics.mainContentWidth,
                        horizontalGutters: 'outerMargin',
                        verticalGutters: 'xl',
                    },
                    contentAreas: {
                        main: [
                            layout.component({
                                type: 'taboola',
                                props: {
                                    taboolaContainerId:
                                        'taboola-below-home-thumbnails',
                                    locationType: 'home',
                                },
                            }),
                        ],
                    },
                }),
            ],
        }
    },
}

function mainContentSection(
    toggleValues: TogglesReduxState,
    components: Array<CheckedComponentInformation>,
) {
    return layout.composition({
        type: 'thor',
        props: {
            hasBackgroundFill: false,
            containerWidth: metrics.thewest.siteMetrics.mainContentWidth,
            horizontalGutters: false,
            verticalGutters: false,
        },
        contentAreas: {
            main: [
                breakingNews,
                promoStrap,
                ...preHomeSegment(toggleValues),
                homeSegment(toggleValues),
                ...postHomeSegment(toggleValues),
                layout.nestedComposition({
                    type: 'thor',
                    props: {
                        hasBackgroundFill: true,
                        containerWidth: '100%',
                        verticalGutters: false,
                        horizontalGutters: true,
                    },
                    contentAreas: {
                        main: components,
                    },
                }),
            ],
        },
    })
}
