import {
    AdEvent,
    AdState,
    AllEvents,
    ArticlePublication,
    ComponentServices,
    EventPublication,
    GalleryPublication,
    mapPublication,
} from '@news-mono/web-common'
import H from 'history'
import { Logger } from 'typescript-log'
import { AdUnitInformation } from '../../advertising/advertising.routing'
import { GenericInArticleBannerProps } from '../../banners/GenericInArticleBanner/GenericInArticleBanner'
import { ImageBannerProps } from '../../banners/ImageBanner/ImageBanner'
import { ImageLinkProps } from '../../buttons/ImageLink'
import { ImageLinkVisibilityMode } from '../../buttons/ImageLink/ImageLink.styled'
import { Section } from '../../__styling/settings/sections'

export interface InlinePublicationContent {
    kind: string
    /**
     * Insert ad after n content blocks, falls back to the PerthNow default of 4 if not
     * specified.
     */
    insertAfter?: number
}

export interface InlineAdContent extends InlinePublicationContent {
    kind: 'ad'
    /**
     * Sometimes we need to insert multiple bits of content into the same position, due to different properties or breakpoints
     */
    content: AdUnitInformation['data'][]
}

export interface InlineOovvuuFeaturedCardContent
    extends InlinePublicationContent {
    kind: 'oovvuu-featured-card'
    props: {
        oovvuuUrl: string
        oovvuuPath: string
    }
}

export interface InlinePointer extends InlinePublicationContent {
    kind: 'pointer'
    props: {
        pointerLinkUrl: string
        pointerImageSrc: string
        pointerImageAlt: string
    }
}

export interface InlineSportCallToAction extends InlinePublicationContent {
    kind: 'sport-call-to-action'
}

export interface InlineSportsHubCallToAction extends InlinePublicationContent {
    kind: 'sports-hub-call-to-action'
}

interface InlineImageLinkProps extends ImageLinkProps {
    visiblility: ImageLinkVisibilityMode
    displayCondition?: boolean
}

export interface InlineImageLink extends InlinePublicationContent {
    kind: 'image-link'
    props: InlineImageLinkProps
}

export interface InlineCngNewsletterBanner extends InlinePublicationContent {
    kind: 'inline-cng-newsletter-banner'
    props: InlineImageLinkProps
    feature?: string
}

export interface InlineWestLiveBanner extends InlinePublicationContent {
    kind: 'the-west-live-in-article-banner'
}

export interface InlineTaboolaAmpComponent extends InlinePublicationContent {
    kind: 'inline-taboola-amp'
    insertAfter: number
}
export interface InlineTaboolaComponent extends InlinePublicationContent {
    kind: 'inline-taboola'
    insertAfter: number
}

export interface InlineArticleContextualLink extends InlinePublicationContent {
    kind: 'inline-article-contextual-link'
}

export interface InlinePollieRaterBanner extends InlinePublicationContent {
    kind: 'inline-pollie-rater-banner'
}
export interface InlineFederalElectionBanner extends InlinePublicationContent {
    kind: 'inline-federal-election-banner'
}

export interface InlineGenericArticleBanner extends InlinePublicationContent {
    kind: 'inline-generic-article-banner'
    props: Omit<GenericInArticleBannerProps, 'onEvent'>
}

export interface InlineQueenTributeBanner
    extends Omit<InlineGenericArticleBanner, 'kind'> {
    kind: 'inline-queen-tribute-banner'
}

export interface InlineGoMatildasBanner
    extends Omit<InlineGenericArticleBanner, 'kind'> {
    kind: 'inline-go-matildas-banner'
}

export interface InlineCourtInTheActBanner
    extends Omit<InlineGenericArticleBanner, 'kind'> {
    kind: 'inline-court-in-the-act-banner'
}

export interface InlineVanishingCousinsBanner
    extends Omit<InlineGenericArticleBanner, 'kind'> {
    kind: 'inline-vanishing-cousins-banner'
}

export interface InlineReadersSurveyBanner
    extends Omit<InlineCngNewsletterBanner, 'kind'> {
    kind: 'inline-readers-survey-banner'
}

interface InlineMatchCentreBannerProps extends Partial<ImageBannerProps> {
    toggle: boolean
}
export interface InlineMatchCentreBanner extends InlinePublicationContent {
    kind: 'inline-match-centre-banner'
    props: InlineMatchCentreBannerProps
}

interface InlineTheGameBannerProps extends Partial<ImageBannerProps> {
    toggle: boolean
}
export interface InlineTheGameBanner extends InlinePublicationContent {
    kind: 'inline-the-game-banner'
    props: InlineTheGameBannerProps
}

export interface InlinePNDigitalEditionPromo extends InlinePublicationContent {
    kind: 'inline-pn-digital-edition-promo'
    props: {
        displayCondition: boolean
    }
}
export interface InlineTNRegisterAccount extends InlinePublicationContent {
    kind: 'inline-tn-register-account'
}

export interface InlineTNNewsletterBanner extends InlinePublicationContent {
    kind: 'inline-tn-newsletter-banner'
}

export interface InlineNightlyPromoBanner extends InlinePublicationContent {
    kind: 'inline-nightly-promo-banner'
    toggle: boolean
}

export interface InlineOlympicsMedalWidget extends InlinePublicationContent {
    kind: 'inline-olympics-medal-widget'
    toggle: boolean
}

export interface InlineFederalElectionNeedleWidget
    extends InlinePublicationContent {
    kind: 'inline-federal-election-needle-widget'
    toggle: boolean
}

export type InlineContentTypes =
    | InlineAdContent
    | InlineOovvuuFeaturedCardContent
    | InlinePointer
    | InlineSportCallToAction
    | InlineImageLink
    | InlineWestLiveBanner
    | InlineCngNewsletterBanner
    | InlineSportsHubCallToAction
    | InlineTaboolaAmpComponent
    | InlineTaboolaComponent
    | InlineArticleContextualLink
    | InlineFederalElectionBanner
    | InlinePollieRaterBanner
    | InlinePNDigitalEditionPromo
    | InlineGenericArticleBanner
    | InlineQueenTributeBanner
    | InlineMatchCentreBanner
    | InlineGoMatildasBanner
    | InlineReadersSurveyBanner
    | InlineCourtInTheActBanner
    | InlineVanishingCousinsBanner
    | InlineTheGameBanner
    | InlineTNRegisterAccount
    | InlineTNNewsletterBanner
    | InlineNightlyPromoBanner
    | InlineOlympicsMedalWidget
    | InlineFederalElectionNeedleWidget

export interface GalleryPublicationInformation {
    kind: 'gallery'
    slug: string
    /**
     * If we don't have route hints we will load the full publication when resolving
     * the route. When we have route hints we won't need to so the ContentAreaDataLoader
     * will load the publication
     */
    numberImages: number
    /* type: ad-unit, and data: AdUnitProps */
    galleryAds: AdUnitInformation['data'][]
    section?: string
}

export interface RemainingPublicationInformation {
    kind: 'article'
    slug: string
    section?: string
    inlinePublicationContent?: InlineContentTypes[]
    /**
     * If we don't have route hints we will load the full publication when resolving
     * the route. When we have route hints we won't need to so the ContentAreaDataLoader
     * will load the publication
     */
}

export interface PublicationRouteProps {
    meta: GalleryPublicationInformation | RemainingPublicationInformation
    section: Section
    isVideoStickyEnabled?: boolean
}

export interface GalleryPublicationProps {
    gallery: GalleryPublication
    galleryMeta: GalleryPublicationInformation
    onEvent: (event: AllEvents) => void
    location: H.Location
    adState: AdState
    log: Logger
}

export function getPublicationInfoFromRouteResolution(
    services: ComponentServices,
) {
    if (services.resolution.type !== 'server') {
        throw new Error('Publications must be server resolved')
    }

    if (
        services.resolution.resolution.type !== 'publication' &&
        services.resolution.resolution.type !== 'preview'
    ) {
        throw new Error(
            'Server resolution is not publication, should not be rendering publication',
        )
    }

    const publication = mapPublication(
        services.resolution.resolution.publication,
        services.store.getState().toggles,
    )
    return {
        publication,
        section: services.resolution.resolution.section as Section,
    }
}

export function getVideoSeriesEpisodeInfoFromRouteResolution(
    services: ComponentServices,
) {
    if (services.resolution.type !== 'server') {
        throw new Error('Publications must be server resolved')
    }

    if (services.resolution.resolution.type !== 'video-series-episode') {
        throw new Error(
            'Server resolution is not publication, should not be rendering publication',
        )
    }

    return services.resolution.resolution
}

export type PublicationTypes =
    | ArticlePublication
    | GalleryPublication
    | EventPublication
    | undefined
export type MetaTypes =
    | GalleryPublicationInformation
    | RemainingPublicationInformation
