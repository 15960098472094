import React, { useEffect } from 'react'
import { AllEvents, Election } from '@news-mono/web-common'
import {
    ElectionDataFetchError,
    ElectionText,
    ElectionTitle,
    ElectionTitleButton,
} from '../components'
import {
    getElectionPartyData,
    InterknowlogyData,
    TopPartyResults,
    transformAreaData,
} from '../data'
import { TheRaceRow } from './components/TheRaceRow/TheRaceRow'
import {
    TheRaceWidgetChevron,
    TheRaceWidgetContainer,
    TheRaceWidgetGraphContainer,
    TheRaceWidgetGraphDataContainer,
} from './TheRaceWidget.styled'
import { TheRaceRowPlaceholder } from './components/TheRaceRowPlaceholder/TheRaceRowPlaceholder'
import { TheRaceWinIndicator } from './components/TheRaceWinIndicator'
import { TransformedData } from '@west-australian-newspapers/election-api-types'
import { MaybeLoaded } from 'json-react-layouts-data-loader'

export interface TheRaceWidgetProps {
    data: MaybeLoaded<TransformedData | undefined>
    election: Election
    partiesToShow?: number
    redirectTo?: string
    onEvent: (event: AllEvents) => void
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

export const TheRaceWidget = ({
    data,
    election,
    partiesToShow = 5,
    redirectTo,
    onEvent,
    headingLevel,
}: TheRaceWidgetProps) => {
    //set up initial data if available
    const initialData =
        data.loaded && data.result
            ? getElectionPartyData({
                  dataFeed: transformAreaData(data.result as InterknowlogyData),
                  partiesToShow,
              })
            : undefined
    const [partyData, setPartyData] = React.useState<
        TopPartyResults | undefined
    >(initialData)

    const ref = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (data.loaded && data.result) {
            const interknowlogyData = transformAreaData(
                data.result as InterknowlogyData,
            )
            const elec = getElectionPartyData({
                dataFeed: interknowlogyData,
                partiesToShow,
            })
            setPartyData(elec)
        }
    }, [data, partiesToShow])

    return (
        <TheRaceWidgetContainer>
            <ElectionTitleButton to={redirectTo}>
                <ElectionTitle as={headingLevel}>The Race</ElectionTitle>
                {redirectTo && <TheRaceWidgetChevron />}
            </ElectionTitleButton>
            <TheRaceWidgetGraphContainer ref={ref}>
                {!partyData ? (
                    data.loaded ? (
                        <ElectionDataFetchError />
                    ) : (
                        <LoadingData partiesToShow={partiesToShow} />
                    )
                ) : (
                    <LoadedData
                        partiesToShow={partiesToShow}
                        partyData={partyData}
                        election={election}
                    />
                )}
            </TheRaceWidgetGraphContainer>
            <ElectionText>
                Using current counted votes and data from X, we can predict the
                outcome of the election.
            </ElectionText>
        </TheRaceWidgetContainer>
    )
}

type LoadingDataProps = {
    partiesToShow: number
}

const LoadingData = ({ partiesToShow }: LoadingDataProps) => {
    return (
        <TheRaceWidgetGraphDataContainer>
            {Array(partiesToShow)
                .fill(0)
                .map((_, index) => (
                    <TheRaceRowPlaceholder key={index} />
                ))}
        </TheRaceWidgetGraphDataContainer>
    )
}

interface LoadedDataProps {
    partyData: TopPartyResults | undefined
    election: Election
    partiesToShow: number
}
const LoadedData = ({
    partyData,
    election,
    partiesToShow,
}: LoadedDataProps) => {
    const totalBarLength = election.barTotalSeats
    const percentPerSeat = 100 / totalBarLength

    return (
        <>
            <TheRaceWinIndicator
                election={election}
                percentPerSeat={percentPerSeat}
            />
            <TheRaceWidgetGraphDataContainer>
                {/* Now implement each individual row! */}
                {partyData?.parties.slice(0, partiesToShow).map((party) => (
                    <TheRaceRow
                        party={party}
                        percentPerSeat={percentPerSeat}
                        seatsToWin={election.seatsToWin}
                    />
                ))}
            </TheRaceWidgetGraphDataContainer>
        </>
    )
}
