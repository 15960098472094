import React, { PropsWithChildren, ReactNode } from 'react'
import {
    StyledLatestLiveBlogMilestonesContainer,
    StyledLiveBlogBulletMilestoneMark,
    StyledLiveBlogBulletPoint,
    StyledLiveBlogBulletPointContainer,
    StyledLiveBlogMilestoneRow,
    StyledLiveBlogRowContentContainer,
    StyledLiveBlogRowTimestamp,
    StyledLiveBlogRowTitle,
} from './LatestLiveBlogMilestones.styled'
import { getReadableTimeSince } from '../../../__helpers'
import { IconBulletPoint } from '../../../icons'
import { format } from 'date-fns'
import { LatestLiveBlogContent } from '../content-selector-helper'

export type GetLinkWithChildrenComponent = (
    props: PropsWithChildren<{ to: string; key: string }>,
) => ReactNode

export interface LatestLiveBlogMilestonesProps {
    posts: LatestLiveBlogContent[]
    isVrTest?: boolean
    getLinkComponentWithChildren?: GetLinkWithChildrenComponent
}

export const LatestLiveBlogMilestones = ({
    posts,
    isVrTest,
    getLinkComponentWithChildren,
}: LatestLiveBlogMilestonesProps) => {
    const size = posts.length

    return (
        <StyledLatestLiveBlogMilestonesContainer>
            {posts.map((content, index) => {
                const RowComponent = (
                    <LiveBlogMilestoneRow
                        content={content}
                        isVrTest={isVrTest}
                        isLastRow={size - 1 === index}
                    />
                )
                return getLinkComponentWithChildren
                    ? getLinkComponentWithChildren({
                          to: content.link,
                          key: content.postId,
                          children: RowComponent,
                      })
                    : RowComponent
            })}
        </StyledLatestLiveBlogMilestonesContainer>
    )
}

const LiveBlogMilestoneRow = ({
    content,
    isVrTest,
    isLastRow,
}: {
    content: LatestLiveBlogContent
    isVrTest?: boolean
    isLastRow: boolean
}) => {
    const { title, publishedDate, isPinned } = content
    const truncTitle = isPinned ? `Pinned: ${title}` : title

    const publicationDateMessage = getReadableTimeSince({
        publicationDate: publishedDate,
        comparisonDate: new Date().toISOString(),
    })
    const timeOnlyText = format(new Date(publishedDate), 'hh:mm a')

    return (
        <StyledLiveBlogMilestoneRow>
            {/* Styling for the bullet point at the start of the post */}
            <StyledLiveBlogBulletPoint>
                <StyledLiveBlogBulletPointContainer isVrTest={isVrTest}>
                    <IconBulletPoint />
                </StyledLiveBlogBulletPointContainer>
                {!isPinned && <StyledLiveBlogBulletMilestoneMark />}
            </StyledLiveBlogBulletPoint>
            {/* Styling the content for row */}
            <StyledLiveBlogRowContentContainer isLastRow={isLastRow}>
                <StyledLiveBlogRowTitle>{truncTitle}</StyledLiveBlogRowTitle>
                <StyledLiveBlogRowTimestamp>
                    {publicationDateMessage} - {timeOnlyText}
                </StyledLiveBlogRowTimestamp>
            </StyledLiveBlogRowContentContainer>
        </StyledLiveBlogMilestoneRow>
    )
}
