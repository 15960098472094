import styled from '@emotion/styled'
import { calcRem, themedValue } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { CSSObject, keyframes } from '@emotion/css'
import { WebLink } from '@news-mono/web-common'
import { placeholderAnimation } from '../components/placeholderAnimation'

// Shared styles required by link card and embed card
export const sharedStyles: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: calcRem(126), // Fixed height for skeleton
    minWidth: calcRem(342),
    gap: calcRem(8),
    background: tokens.thenightly.colors.palette.white,
    borderRadius: calcRem(12),
    border: `${calcRem(1)} solid ${
        tokens.thenightly.colors.palette.neutral[20]
    }`,
}

export const SeatContainerEmbed = styled.div({
    // Shared styling
    ...sharedStyles,

    padding: calcRem(8),
    minWidth: '100%',
    maxWidth: '100%',
    margin: 0,
})

export const SeatContainerLink = styled(WebLink)(({ theme }) => {
    return {
        // Shared styling
        ...sharedStyles,
        padding: calcRem(8),

        // Reset link styles
        textDecoration: 'none',
        color: 'inherit',

        outline: 'none',

        ['&:is(:hover, :focus)']: {
            borderColor: theme.colors.text.tertiary,
        },
    }
})

export const SeatHeaderContainer = styled('div')({
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: calcRem(8),
    maxHeight: calcRem(24),
})

export const SeatDetailsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: calcRem(4),
    maxHeight: calcRem(24),
    margin: 0,
})

interface SeatDetailsTextProps {
    variant: 'name' | 'state'
}

export const SeatDetailsText = styled('p')<SeatDetailsTextProps>(
    ({ variant, theme }) => ({
        margin: 0,
        fontSize: calcRem(16),
        fontWeight: variant === 'name' ? 700 : 500,
        color:
            variant === 'name'
                ? tokens.thenightly.colors.palette.neutral[100]
                : tokens.thenightly.colors.palette.neutral[70],
        lineHeight: calcRem(24),
        fontFamily: theme.fonts.sansSerif,
    }),
)

interface SeatStatusContainerProps {
    color: string
}

export const SeatStatusContainer = styled('div')<SeatStatusContainerProps>(
    ({ color }) => ({
        display: 'flex',
        alignItems: 'flex-start',
        minHeight: calcRem(16),
        background: color,
        padding: `${calcRem(4)} ${calcRem(12)}`,
        width: 'max-content',
        borderRadius: calcRem(40),
    }),
)

export const SeatsStatusText = styled('p')(({ theme }) => ({
    margin: 0,
    fontSize: themedValue(theme, {
        thenightly: calcRem(12),
        thewest: calcRem(13),
        fallback: calcRem(12),
    }),
    fontWeight: 400,
    lineHeight: calcRem(16),
    color: tokens.thenightly.colors.palette.neutral[0],
    fontFamily: theme.fonts.sansSerif,
}))

export const SeatResultsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(4),
    alignSelf: 'stretch',
})

export const SeatVotesContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    height: calcRem(24),
})

interface SeatVoteCountProps {
    color: string
}

export const SeatVoteCountContainer = styled('div')<SeatVoteCountProps>(
    ({ color }) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: calcRem(8),
        color,
    }),
)

interface SeatVoteCountTextProps {
    variant: 'percentage' | 'count'
}

export const SeatVoteCountText = styled('p')<SeatVoteCountTextProps>(
    ({ theme, variant }) => ({
        fontSize: calcRem(16),
        fontWeight: variant === 'percentage' ? 700 : 500,
        fontFamily: theme.fonts.sansSerif,
    }),
)

export const SeatCandidatesContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    height: calcRem(36),
})

export const SeatCandidateContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: calcRem(36),
    gap: calcRem(8),
})

export const SeatCandidateImage = styled('img')({
    width: calcRem(32),
    height: calcRem(32),
    borderRadius: calcRem(60),
    backgroundRepeat: 'no-repeat',
},placeholderAnimation)

interface SeatCandidateDetailsProps {
    reverse: boolean
}

export const SeatCandidateDetails = styled('div')<SeatCandidateDetailsProps>(
    ({ theme, reverse }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%',
        paddingTop: calcRem(4),
        alignItems: reverse ? 'flex-end' : 'flex-start',
        textAlign: reverse ? 'right' : 'left',
        flex: `1 0 0`,
        fontFamily: theme.fonts.sansSerif,
    }),
)

interface SeatCandidateTextProps {
    incumbent: boolean
}

export const SeatCandidateNameText = styled('p')<SeatCandidateTextProps>(
    ({ incumbent, theme }) => ({
        margin: 0,
        fontSize: themedValue(theme, {
            thenightly: calcRem(12),
            thewest: calcRem(13),
            fallback: calcRem(12),
        }),
        fontWeight: incumbent ? 700 : 400,
        lineHeight: calcRem(16),
        fontFamily: theme.fonts.sansSerif,
    }),
)

interface SeatCandidatePartyTextProps {
    color: string
}

export const SeatCandidatePartyText = styled('p')<SeatCandidatePartyTextProps>(
    ({ color, theme }) => ({
        margin: 0,
        fontSize: themedValue(theme, {
            thenightly: calcRem(12),
            thewest: calcRem(13),
            fallback: calcRem(12),
        }),
        fontWeight: 600,
        color,
        lineHeight: calcRem(16),
        fontFamily: theme.fonts.sansSerif,
    }),
)

export const SeatBarGraphContainer = styled('div')({
    position: 'relative',
    display: 'flex',
    height: calcRem(8),
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: calcRem(4),
    background: tokens.thenightly.colors.palette.white,
    borderRadius: calcRem(4),
})

export const SeatBarGraphDivider = styled('div')({
    margin: 0,
    position: 'absolute',
    left: '50%',
    top: `-${calcRem(4)}`,
    bottom: 0,
    width: calcRem(4),
    height: calcRem(16),
    backgroundColor: tokens.thenightly.colors.palette.neutral[70],
    border: `${calcRem(1)} solid ${tokens.thenightly.colors.palette.white}`,
})

interface SeatBarGraphSlidingBarProps {
    candidateOneColor: string
    candidateTwoColor: string
    partyOnePct: number | null
}

export const SeatBarGraphSlidingBar = styled(
    'div',
)<SeatBarGraphSlidingBarProps>(
    ({ candidateOneColor, candidateTwoColor, partyOnePct }) => ({
        width: '100%',
        height: calcRem(8),
        background: `linear-gradient(
        to right,
        ${candidateOneColor} ${partyOnePct}%,
        ${candidateTwoColor} ${partyOnePct}% 100%
    )`,
        position: 'relative',
    }),
)

// Skeletons

export const LoadingPlaceholder = styled('div')(
    sharedStyles,
    placeholderAnimation,
)
