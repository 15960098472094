import { cx } from '@emotion/css'
import {
    DataLayerEventName,
    Election,
    ElectionLogo,
    getElectionId,
    NavClickedEvent,
    useElectionToggles,
} from '@news-mono/web-common'
import React, { Fragment, useCallback } from 'react'
import { breakpoint } from '../../__styling'
import {
    NavigationContainer,
    StyledPicture,
    StyledLink,
    LogoLink,
    Heading,
} from './TNElectionNavigation.styled'

export type TNElectionNavigationProps = {
    election: Election
    electionLogo: ElectionLogo
    activePage?: 'updates' | 'electorate-results' | 'party-totals'
    headingLevel?: 'h1' | 'h2'
    onEvent?: (event: NavClickedEvent) => void
    className?: string
}

export const TNElectionNavigation = ({
    election,
    electionLogo,
    activePage,
    headingLevel,
    onEvent,
    className,
}: TNElectionNavigationProps) => {
    const {
        'election-navigation': isEnabled,
        'election-landing-page-route': isLandingPageEnabled,
        'election-electorate-results-route': isElectorateResultsPageEnabled,
        'election-party-totals-route': isPartyTotalsPageEnabled,
    } = useElectionToggles(election)

    const updatesLink = `/politics/${getElectionId(election)}`
    const electoratesLink = `${updatesLink}/electorates`
    const partyTotalsLink = `${updatesLink}/party-totals`

    const handleUpdatesNavigation = useCallback(
        () =>
            onEvent?.(
                getNavEvent({ navText: 'Updates', navLink: updatesLink }),
            ),
        [onEvent, updatesLink],
    )

    const handleElectorateResultsNavigation = useCallback(
        () =>
            onEvent?.(
                getNavEvent({
                    navText: 'Electorate Results',
                    navLink: electoratesLink,
                }),
            ),
        [onEvent, electoratesLink],
    )

    const handlePartyTotalsNavigation = useCallback(
        () =>
            onEvent?.(
                getNavEvent({
                    navText: 'Party Totals',
                    navLink: partyTotalsLink,
                }),
            ),
        [onEvent, partyTotalsLink],
    )

    if (!isEnabled) {
        return null
    }

    const picture = (
        <StyledPicture>
            <source
                media={breakpoint('sm').replace('@media ', '')}
                srcSet={electionLogo.desktop}
            />
            <img src={electionLogo.mobile} alt={electionLogo.alt} />
        </StyledPicture>
    )

    return (
        <NavigationContainer className={className}>
            <Heading as={headingLevel ?? Fragment}>
                {isLandingPageEnabled ? (
                    <LogoLink
                        to={updatesLink}
                        aria-label={'Go to the election landing page.'}
                        onClick={handleUpdatesNavigation}
                        forceInternalSSR
                    >
                        {picture}
                    </LogoLink>
                ) : (
                    picture
                )}
            </Heading>
            {isLandingPageEnabled && (
                <StyledLink
                    to={updatesLink}
                    className={cx([activePage === 'updates' && 'active'])}
                    onClick={handleUpdatesNavigation}
                    forceInternalSSR
                >
                    Updates
                </StyledLink>
            )}
            {isElectorateResultsPageEnabled && (
                <StyledLink
                    to={electoratesLink}
                    className={cx([
                        activePage === 'electorate-results' && 'active',
                    ])}
                    onClick={handleElectorateResultsNavigation}
                    forceInternalSSR
                >
                    Electorate Results
                </StyledLink>
            )}
            {isPartyTotalsPageEnabled && (
                <StyledLink
                    to={partyTotalsLink}
                    className={cx([activePage === 'party-totals' && 'active'])}
                    onClick={handlePartyTotalsNavigation}
                    forceInternalSSR
                >
                    Party Totals
                </StyledLink>
            )}
        </NavigationContainer>
    )
}

type GetNavEventArgs = { navText: string; navLink: string }

/**
 * Creates a nav event for TNElectionNavigation.
 */
const getNavEvent = ({
    navText,
    navLink,
}: GetNavEventArgs): NavClickedEvent => ({
    type: DataLayerEventName.navClicked,
    originator: 'TNElectionNavigation',
    payload: {
        navName: 'ElectionNavigation',
        navLocation: 'OnPage',
        navText,
        navLink,
    },
})
