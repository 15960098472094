import styled from '@emotion/styled'
import React from 'react'
import { breakpoint, calcRem } from '../../../../__styling'
import { ElectionNoteText } from '../../components/ElectionNoteText'
import { RaceLine44 } from '../../components/RaceLine44'
import { Election } from '@news-mono/web-common'

export interface TheRaceWinIndicatorProps {
    election: Election
    percentPerSeat: number
}
export const TheRaceWinIndicator = ({
    election,
    percentPerSeat,
}: TheRaceWinIndicatorProps) => {
    const toWinText = `${election.seatsToWin} to win`

    return (
        <TheRaceGraphWinGraphContainer>
            <TheRaceGraphWinIndicator
                percentFromRight={percentPerSeat * election.seatsToWin}
            >
                <ElectionNoteText>{toWinText}</ElectionNoteText>
                <RaceLine44 />
            </TheRaceGraphWinIndicator>
        </TheRaceGraphWinGraphContainer>
    )
}

const TheRaceGraphWinGraphContainer = styled('div')({
    height: '100%',

    display: 'flex',
    position: 'absolute',
    top: 0,
    right: 0,

    // Mobile Styling
    width: '100%',

    // Desktop Styling
    [breakpoint('lg')]: {
        width: '90%',
    },
})

const TheRaceGraphWinIndicator = styled('div')<{
    percentFromRight: number
}>(({ percentFromRight }) => ({
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',

    right: `${100 - percentFromRight}%`,
    transform: 'translateX(50%)',
    position: 'absolute',

    // Mobile styling
    height: '100%',

    // Bigger styling
    [breakpoint('lg')]: {
        height: `calc(100% + ${calcRem(16)})`,
        top: calcRem(-16),
    },
}))
