import {
    getElectionId,
    TogglesReduxState,
    ElectionDefinition,
    getElectionToggles,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { calcRem } from '@news-mono/component-library'
import { billboardLeaderboardMrec } from '../ad-units'

export const getElectionSegment = (
    { election, logo }: ElectionDefinition,
    toggles: TogglesReduxState,
) => {
    const {
        'election-navigation': isNavigationEnabled,
        'election-homepage-the-race': isTheRaceEnabled,
        'election-homepage-the-seats': isTheSeatsEnabled,
        'election-homepage-collection': isCollectionEnabled,
    } = getElectionToggles(election, toggles)

    return isCollectionEnabled
        ? [
              ...(isNavigationEnabled
                  ? [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                verticalSpacing: 'md',
                                breakpoints: {
                                    lg: {
                                        verticalSpacing: 'xxxl',
                                    },
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'election-navigation',
                                        props: {
                                            election,
                                            electionLogo: logo,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ]
                  : []),

              layout.nestedComposition({
                  type: 'thor',
                  props: {
                      hasBackgroundFill: false,
                      containerWidth: '100%',
                      verticalGutters: false,
                      horiziontalGutters: true,
                  },
                  contentAreas: {
                      main: [
                          ...(isTheRaceEnabled
                              ? [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            verticalSpacing: 'md',
                                            breakpoints: {
                                                lg: {
                                                    verticalSpacing: 'xxxl',
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'election-the-race-widget',

                                                    props: {
                                                        election,
                                                        headingLevel: 'h3',
                                                        dataDefinitionArgs: {
                                                            type: 'election',
                                                            electionId: 632,
                                                            pageSize: 1,
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ]
                              : []),

                          layout.nestedComposition({
                              type: 'box',
                              props: {
                                  verticalSpacing: 'md',
                                  breakpoints: {
                                      lg: {
                                          verticalSpacing: 'xxxl',
                                      },
                                  },
                              },
                              contentAreas: {
                                  main: [
                                      layout.component({
                                          type: 'sierra',
                                          props: {
                                              cardLayout: [
                                                  'the-west-hero',
                                                  {
                                                      type: 'november',
                                                      listLength: 4,
                                                      hasBackground: false, //So that the border appears on the last item ¯\_(ツ)_/¯
                                                      paddingOverride: calcRem(
                                                          16,
                                                          0,
                                                          0,
                                                          0,
                                                      ),
                                                  },
                                              ],
                                              isLarge: true,
                                              heroBottomPadding: 8,
                                              dataDefinitionArgs: {
                                                  type: 'curation',
                                                  name: getElectionId(election),
                                                  pageSize: 5,
                                                  offset: 0,
                                              },
                                              gridGap: 24,
                                              breakpoints: {
                                                  lg: {
                                                      gridGap: 32,
                                                  },
                                              },
                                          },
                                      }),
                                  ],
                              },
                          }),
                          ...(isTheSeatsEnabled
                              ? [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            verticalSpacing: 'md',
                                            breakpoints: {
                                                lg: {
                                                    verticalSpacing: 'xxxl',
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'election-seat-widget',

                                                    props: {
                                                        dataDefinitionArgs: {
                                                            type: 'election',
                                                            electionId: 632,
                                                            pageSize: 1, // Throwaway value, required to pass type check
                                                        },
                                                        electoratePageUrl: `/politics/${getElectionId(
                                                            election,
                                                        )}/electorates`,
                                                        headingLevel: 'h3',
                                                        titleText:
                                                            'Electorate Results',
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ]
                              : []),
                      ],
                  },
              }),

              ...billboardLeaderboardMrec('election'),
          ]
        : []
}
