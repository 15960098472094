import React, { useEffect, useState } from 'react'
import { StyledPNVideoPlayButton } from './PNVideoPlayButton.styled'
import { IconVideoPause, IconVideoPlay } from '../../../icons'
import { PNCardOverlayButtonScale } from '../cards/PNArticleCard'

export interface PNVideoPlayButtonProps {
    overlayButtonScale?: PNCardOverlayButtonScale
    isCentred?: boolean
    onPlayButtonClick?: () => void
    status?: 'playing' | 'paused' | 'hidden' | 'static'
    isTouchDevice?: boolean | RegExpMatchArray | null
    // define the button behavior on article page
    playButtonProperty?: {}
}

export const PNVideoPlayButton: React.FC<PNVideoPlayButtonProps> = ({
    overlayButtonScale = 'M',
    isCentred,
    onPlayButtonClick,
    status = 'static',
    isTouchDevice = false,
}) => {
    const [show, setShow] = useState(status !== 'hidden')
    const [hasBackground, setHasBackground] = useState(false)

    useEffect(() => {
        if (status === 'static') {
            setShow(true)
            return
        }

        if (status === 'hidden') {
            setShow(false)
            return
        }

        setShow(true)
        setHasBackground(true)
        const backgroundTimer = setTimeout(() => {
            setHasBackground(false)
        }, 400)
        const displayTimer = setTimeout(() => {
            setShow(false)
        }, 1500)
        return () => {
            clearTimeout(backgroundTimer)
            clearTimeout(displayTimer)
        }
    }, [status])

    function handleClick() {
        setHasBackground(true)
        onPlayButtonClick && !isTouchDevice && onPlayButtonClick()
        // quick pink flash upon clicking
        setTimeout(() => {
            setHasBackground(false)
        }, 500)
        setTimeout(() => {
            setShow(false)
        }, 1500)
    }

    return (
        <StyledPNVideoPlayButton
            onClick={handleClick}
            title="Video"
            overlayButtonScale={overlayButtonScale}
            tabIndex={-1}
            isCentred={isCentred}
            visible={show}
            hasBackground={hasBackground}
        >
            {status === 'paused' ? <IconVideoPause /> : <IconVideoPlay />}
        </StyledPNVideoPlayButton>
    )
}
