import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import {
    getSeatsData,
    InterknowlogyData,
    searchSeatsOrCandidates,
    SeatData,
    sortMethods,
    sortSeats,
    transformAreaData,
} from '../data'
import {
    ElectionDataFetchError,
    ElectionFilter,
    ElectionText,
} from '../components'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { TransformedData } from '@west-australian-newspapers/election-api-types'
import { FullElectorateCard } from '../FullElectorateCard/FullElectorateCard'
import {
    useQueryParams,
    AllEvents,
    DataLayerEventName,
} from '@news-mono/web-common'
import { CustomPageSharing } from '../../../buttons/SharingCustomPage/CustomPageSharing'
import { ElectionSearchInput } from '../components/ElectionSearch'
import {
    StyledElectorateTitle,
    StyledElectorateWidgetContainer,
    StyledTitleContainer,
} from './FullElectorateWidget.styled'
import {
    TheSeatsDivider,
    TheSeatsNoResultsText,
} from '../TheSeatsWidget/TheSeatsWidget.styled'

export interface FullElectorateWidgetProps {
    data: MaybeLoaded<TransformedData>
    onEvent: (event: AllEvents) => void
    imageBaseUrl?: string
}

export const FullElectorateWidget = ({
    data,
    onEvent,
    imageBaseUrl,
}: FullElectorateWidgetProps): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const { queryParams, pathname } = useQueryParams()

    const filterResults = useCallback(
        (searchTerm: string, seatData: SeatData[]) => {
            setSearchTerm(searchTerm)
            return searchSeatsOrCandidates(seatData, searchTerm)
        },
        [],
    )
    const getInitialData = useCallback(() => {
        if (!data.loaded || !data.result) return []
        const interknowlogyData = transformAreaData(
            data.result as InterknowlogyData,
        )
        return getSeatsData(interknowlogyData, imageBaseUrl, 144)
    }, [data, imageBaseUrl])

    // This stores the data coming from the API, we currently need to transform it to the shape to suit the data
    const [initialData] = useState(() => {
        const initialSeatData = getInitialData()

        // Check for search query param on load
        const searchParams = queryParams.get('search')

        // If we have a search query param, filter the data based on it
        return searchParams
            ? filterResults(searchParams, initialSeatData)
            : initialSeatData
    })
    // This stores the filtered seat data, that way when the search input is cleared, we can revert back to the original data
    const [seatData, setSeatData] = useState<SeatData[]>(initialData)

    // Reset initial data if it is empty (this can happpen during client side nav) also check for search query params
    useEffect(() => {
        if (data.loaded && seatData.length === 0) {
            // Check for search query param on load
            const searchParams = queryParams.get('search')
            const initialData = searchParams
                ? filterResults(searchParams, getInitialData())
                : getInitialData()

            if (initialData.length !== seatData.length) {
                setSeatData(initialData)
            }
        }
    }, [data, getInitialData, seatData, queryParams, filterResults])

    // We need to track which sort method the user has selected, so we can ensure the served data always follows this.
    const [sortBy, setSortBy] = useState(sortMethods[0])
    const sortedData = useMemo(
        () => sortSeats(seatData, sortBy) || [],
        [seatData, sortBy],
    )

    // This Effect checks for any search params and updates the state if any were found
    useEffect(() => {
        // Check for sort query param on load
        const sortParams = queryParams.get('sort')

        const sortMethod = sortMethods.find(
            (method) => method.paramName === sortParams,
        )
        setSortBy(sortMethod || sortMethods[0])
    }, [queryParams])

    /** Callback to handle filtering the seat data based on users query */
    const handleSearch = useCallback(
        (searchTerm: string) => {
            if (!searchTerm) {
                setSeatData(getInitialData())
            } else {
                const filteredData = filterResults(searchTerm, initialData)
                setSeatData(filteredData)
            }
        },
        [filterResults, getInitialData, initialData],
    )

    // Loading State!
    if (!data.loaded) {
        return (
            <FullElectorateWidgetWrapper headingComponents={[]}>
                {Array(5) // Render 5 skeletons while loading
                    .fill(0)
                    .map((_, index) => (
                        <FullElectorateCard
                            key={index}
                            isLoading
                            onEvent={onEvent}
                        />
                    ))}
            </FullElectorateWidgetWrapper>
        )
    }

    // Error State! (data has loaded, but there is no data)
    if (!data.result) {
        return (
            <FullElectorateWidgetWrapper headingComponents={[]}>
                <ElectionDataFetchError />
            </FullElectorateWidgetWrapper>
        )
    }

    const headingComponents = [
        <CustomPageSharing
            text="WA election 2025 electorate results | The West Australian"
            shareOptions={[
                'facebook',
                'linkedin',
                'twitter',
                'email',
                'clipboard',
            ]}
            loading={false}
            onEvent={onEvent}
        />,
        <ElectionSearchInput
            onEvent={onEvent}
            queryParams={queryParams}
            pathname={pathname}
            onSearch={handleSearch}
        />,
        <ElectionFilter
            headingText="Sort by"
            queryFilterParam="sort"
            dataLayerEventOriginator="ElectionSort"
            filterItems={sortMethods}
            onEvent={onEvent}
            dataLayerEventName={DataLayerEventName.searchFilter}
            defaultParamName={sortMethods[0]} // The default will always be the first sort option
            queryParams={queryParams}
            pathname={pathname}
            selectedItem={sortBy}
            setSelectedItem={setSortBy}
        />,
        <TheSeatsDivider hideOnDesktop hasMargin />,
    ]

    // No Search Results State!
    if (searchTerm && sortedData && sortedData.length <= 0) {
        return (
            <FullElectorateWidgetWrapper headingComponents={headingComponents}>
                <TheSeatsNoResultsText>
                    No results for ‘{searchTerm}’
                </TheSeatsNoResultsText>
                <TheSeatsDivider />
            </FullElectorateWidgetWrapper>
        )
    }

    return (
        <FullElectorateWidgetWrapper headingComponents={headingComponents}>
            {sortedData.map((seat) => (
                <FullElectorateCard
                    key={seat.seatId}
                    seat={seat}
                    onEvent={onEvent}
                />
            ))}
        </FullElectorateWidgetWrapper>
    )
}

const FullElectorateWidgetWrapper = ({
    headingComponents,
    children,
}: PropsWithChildren<{ headingComponents: JSX.Element[] }>) => {
    return (
        <StyledElectorateWidgetContainer>
            <StyledTitleContainer>
                <StyledElectorateTitle>
                    Electorate Results
                </StyledElectorateTitle>
                <ElectionText>
                    Live tracking of the electorate results as they are called.
                </ElectionText>
                {headingComponents}
            </StyledTitleContainer>
            {children}
        </StyledElectorateWidgetContainer>
    )
}
