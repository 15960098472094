import React, { memo } from 'react'

import { SeatData, SeatTwoCandidatePreferred } from '../data/election-data'
import {
    SeatDetailsContainer,
    SeatResultsContainer,
    SeatVotesContainer,
    SeatVoteCountContainer,
    SeatCandidateDetails,
    SeatBarGraphContainer,
    SeatBarGraphSlidingBar,
    SeatBarGraphDivider,
    SeatStatusContainer,
} from '../SeatCard/SeatCard.styled'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'
import {
    ElectorateCandidatePartyText,
    ElectorateDetailsContainer,
    FullElectorateCandidateContainer,
    FullElectorateCandidateImage,
    FullElectorateCandidateNameText,
    FullElectorateCandidatesContainer,
    FullElectorateContainer,
    FullElectorateDetailsText,
    FullElectorateHeaderContainer,
    FullElectoratePartyText,
    FullElectorateStatusText,
    FullElectorateSwingContainer,
    FullElectorateSwingLabel,
    FullElectorateVoteCountText,
    IncumbentLabel,
    ShowMoreButtonTW,
    StyledChevron,
} from './FullElectorateCard.styled'
import { FullElectorateCardRow } from '../FullElectorateRow/FullElectorateRow'
import {
    FullElectorateHeadingText,
    InformationGrid,
} from '../FullElectorateRow/FullElectorateRow.styled'
import { AllEvents, DataLayerEventName } from '@news-mono/web-common'
import { FullElectorateSwing } from './FullElectorateSwing'
import { useTheme } from '@emotion/react'
import { handleImageError } from '../components/helpers'

interface SeatCardProps {
    seat?: SeatData
    isLoading?: boolean
    onEvent: (event: AllEvents) => void
}

export const FullElectorateCard: React.FC<SeatCardProps> = memo(
    ({ seat, isLoading = false, onEvent }) => {
        const theme = useTheme()
        const [isOpen, setIsOpen] = React.useState(false)

        if (isLoading || !seat) {
            return <FullElectorateContainer isLoading={isLoading} />
        }

        const {
            seatName,
            state,
            candidates,
            winningParty,
            status,
            winningPartyDarkColor,
        } = seat

        return (
            <FullElectorateContainer isLoading={isLoading}>
                <FullElectorateHeaderContainer>
                    <SeatDetailsContainer>
                        <FullElectorateDetailsText variant="name">
                            {seatName}
                        </FullElectorateDetailsText>
                        <FullElectorateDetailsText variant="state">
                            {state}
                        </FullElectorateDetailsText>
                    </SeatDetailsContainer>
                    <div>
                        {status !== 'Not Called' &&
                            winningParty &&
                            winningPartyDarkColor && (
                                <SeatStatusContainer
                                    color={winningPartyDarkColor}
                                >
                                    <FullElectorateStatusText>
                                        {winningParty} {status}
                                    </FullElectorateStatusText>
                                </SeatStatusContainer>
                            )}
                    </div>
                </FullElectorateHeaderContainer>
                <SeatResults candidates={candidates} />
                <ElectorateDetailsContainer isOpen={isOpen}>
                    <InformationGrid style={{ alignSelf: 'end' }}>
                        <FullElectorateHeadingText>
                            Vote
                        </FullElectorateHeadingText>
                        <FullElectorateHeadingText>
                            Swing
                        </FullElectorateHeadingText>
                    </InformationGrid>
                    {seat.allCandidates.map((candidate, i) => {
                        return (
                            <FullElectorateCardRow
                                key={i}
                                candidate={candidate}
                            />
                        )
                    })}
                </ElectorateDetailsContainer>
                {theme.kind === 'thenightly' ? (
                    <NightlyButton
                        variant={'default'}
                        text={isOpen ? 'Show Less' : 'Show More'}
                        action={{
                            type: 'button',
                            onClick: () => {
                                onEvent({
                                    type: DataLayerEventName.showMoreToggle,
                                    originator: 'ElectionFullElectorateWidget',
                                    payload: {
                                        toggle: `${isOpen ? 'less' : 'more'}`,
                                        details: `${seatName}`,
                                    },
                                })
                                setIsOpen(!isOpen)
                            },
                        }}
                        color={'primary'}
                        fill={'text'}
                        icon={{
                            IconElement: <StyledChevron isOpen={isOpen} />,
                            iconPosition: 'right',
                        }}
                    />
                ) : (
                    <ShowMoreButtonTW
                        onClick={() => {
                            onEvent({
                                type: DataLayerEventName.showMoreToggle,
                                originator: 'ElectionFullElectorateWidget',
                                payload: {
                                    toggle: `${isOpen ? 'less' : 'more'}`,
                                    details: `${seatName}`,
                                },
                            })
                            setIsOpen(!isOpen)
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                            }}
                        >
                            {isOpen ? 'Show less' : 'Show more'}{' '}
                            <StyledChevron isOpen={isOpen} />
                        </div>
                    </ShowMoreButtonTW>
                )}
            </FullElectorateContainer>
        )
    },
)

const SeatResults: React.FC<{ candidates: SeatTwoCandidatePreferred[] }> = ({
    candidates,
}) => {
    const [candidateOne, candidateTwo] = candidates

    return (
        <SeatResultsContainer>
            <SeatVotesContainer>
                <VoteDisplay
                    votes={candidateOne.primaryVotes}
                    percentage={candidateOne.currentVotesPercentage}
                    color={candidateOne.partyColor.dark}
                    partyName={candidateOne.partyName}
                    reverse={true}
                />
                <VoteDisplay
                    votes={candidateTwo.primaryVotes}
                    percentage={candidateTwo.currentVotesPercentage}
                    color={candidateTwo.partyColor.dark}
                    partyName={candidateTwo.partyName}
                    reverse={false}
                />
            </SeatVotesContainer>
            <SeatBarGraph
                candidateOneColor={candidateOne.partyColor.primary}
                candidateTwoColor={candidateTwo.partyColor.primary}
                candidateOnePct={candidateOne.currentVotesPercentage}
            ></SeatBarGraph>
            <FullElectorateCandidatesContainer>
                <CanditateDisplay
                    candidateName={candidateOne.candidateName}
                    color={candidateOne.partyColor.primary}
                    opposingColor={candidateTwo.partyColor.primary}
                    partyName={candidateOne.partyName}
                    opposingPartyName={candidateTwo.partyName}
                    incumbent={candidateOne.incumbent}
                    imageUrl={candidateOne.imageUrl}
                    reverse={false}
                    swing={candidateOne.swing}
                />
                <CanditateDisplay
                    candidateName={candidateTwo.candidateName}
                    color={candidateTwo.partyColor.primary}
                    opposingColor={candidateOne.partyColor.primary}
                    partyName={candidateTwo.partyName}
                    opposingPartyName={candidateOne.partyName}
                    incumbent={candidateTwo.incumbent} // NB this should always be false, or something has very wrong with democracy
                    imageUrl={candidateTwo.imageUrl}
                    reverse={true}
                    swing={candidateTwo.swing}
                />
            </FullElectorateCandidatesContainer>
        </SeatResultsContainer>
    )
}

const VoteDisplay: React.FC<{
    votes: number
    percentage: number | null
    color: string
    partyName: string | null
    reverse?: boolean
}> = ({ votes, percentage, color, partyName, reverse = false }) => (
    <SeatVoteCountContainer color={color}>
        {reverse ? (
            <>
                <FullElectorateVoteCountText bold>
                    {percentage}%
                </FullElectorateVoteCountText>
                <FullElectorateVoteCountText>
                    {votes.toLocaleString()}
                </FullElectorateVoteCountText>
                <FullElectoratePartyText>{partyName}</FullElectoratePartyText>
            </>
        ) : (
            <>
                <FullElectoratePartyText>{partyName}</FullElectoratePartyText>
                <FullElectorateVoteCountText>
                    {votes.toLocaleString()}
                </FullElectorateVoteCountText>
                <FullElectorateVoteCountText bold>
                    {percentage}%
                </FullElectorateVoteCountText>
            </>
        )}
    </SeatVoteCountContainer>
)

const CanditateDisplay: React.FC<{
    candidateName: string
    color: string
    partyName: string | null
    opposingPartyName: string | null
    opposingColor: string
    incumbent: boolean
    imageUrl: string
    swing: number | null
    reverse?: boolean
}> = ({
    candidateName,
    color,
    opposingColor,
    partyName,
    opposingPartyName,
    incumbent,
    imageUrl,
    reverse = false,
    swing,
}) => (
    <FullElectorateCandidateContainer>
        {reverse ? (
            <>
                <SeatCandidateDetails reverse={reverse}>
                    <ElectorateCandidatePartyText color={color}>
                        {partyName}
                    </ElectorateCandidatePartyText>
                    <FullElectorateCandidateNameText>
                        {candidateName}
                    </FullElectorateCandidateNameText>
                    {incumbent && <IncumbentLabel>Incumbent</IncumbentLabel>}
                </SeatCandidateDetails>
                <FullElectorateCandidateImage
                    src={imageUrl}
                    alt={candidateName}
                    onError={handleImageError}
                />
                {incumbent && (
                    <FullElectorateSwing swing={swing} color={color} />
                )}
            </>
        ) : (
            <>
                <FullElectorateCandidateImage
                    src={imageUrl}
                    alt={candidateName}
                    onError={handleImageError}
                />
                <SeatCandidateDetails reverse={reverse}>
                    <ElectorateCandidatePartyText color={color}>
                        {partyName}
                    </ElectorateCandidatePartyText>
                    <FullElectorateCandidateNameText>
                        {candidateName}
                    </FullElectorateCandidateNameText>
                    {incumbent && <IncumbentLabel>Incumbent</IncumbentLabel>}
                </SeatCandidateDetails>
                {incumbent && swing && (
                    <FullElectorateSwingContainer>
                        <FullElectorateSwing
                            swing={swing * -1}
                            color={swing > 0 ? color : opposingColor}
                        />
                        <FullElectorateSwingLabel>{`Swing to ${
                            swing > 0 ? partyName : opposingPartyName
                        }`}</FullElectorateSwingLabel>
                    </FullElectorateSwingContainer>
                )}
            </>
        )}
    </FullElectorateCandidateContainer>
)

export const SeatBarGraph: React.FC<{
    candidateOneColor: string
    candidateTwoColor: string
    candidateOnePct: number | null
}> = ({ candidateOneColor, candidateTwoColor, candidateOnePct }) => {
    return (
        <SeatBarGraphContainer>
            <SeatBarGraphSlidingBar
                candidateOneColor={candidateOneColor}
                candidateTwoColor={candidateTwoColor}
                partyOnePct={candidateOnePct}
            />
            <SeatBarGraphDivider />
        </SeatBarGraphContainer>
    )
}
