import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TogglesReduxState } from '../../feature-togglings'
import { AppState } from '../../store'
import { Election, StateElection } from './electionTypes'

/** The feature toggle prefixes. E.g. 'federal-', 'wa-'. */
type ElectionPrefix = `${StateElection['state'] | 'federal'}-`

const specificElectionFeatures = [
    `election-electorate-results-route`,
    `election-landing-page-the-seats`,
    `election-landing-page-the-race`,
    `election-landing-page-route`,
    `election-landing-page-the-swing`,
    `election-landing-page-map`,
    `election-homepage-the-seats`,
    `election-homepage-the-race`,
    `election-homepage-collection`,
    `election-homepage-collection-position`,
    `election-navigation`,
    `election-sitewide-navigation`,
    `election-homepage-head-to-head`,
    `election-homepage-the-swing`,
    `election-homepage-map`,
    `election-navigation`,
    `election-sitewide-navigation`,
    `election-section-head-to-head`,
    `election-party-totals-route`,
    `election-pollie-rater`,
    `election-homepage-pollie-rater-promo`,
] as const

/** Features that need to be controlled individually to each state/federal election. */
type SpecificElectionFeatures = typeof specificElectionFeatures[number]

export type ElectionFeatures =
    | 'pollie-rater-drivers'
    | 'federal-election-2022-pollie-rater'
    | 'federal-election-2022-pollie-rater-strap-driver'
    | 'federal-election-2022-live-blog-drivers'
    | 'election-widget-test-page'
    | 'election-widget-preview-page'
    | '2025-election-id'
    | 'custom-election-embeds'

    /** All combinations of prefixes and specific-election features. ~80 toggles */
    | `${ElectionPrefix}${SpecificElectionFeatures}`

/**
 * Returns the toggle state for a specific election.
 */
export const getElectionToggles = (
    election: Election,
    toggles: TogglesReduxState,
): Record<SpecificElectionFeatures, boolean | string> => {
    // Set toggles to base toggles.
    const electionToggles = Object.fromEntries(
        specificElectionFeatures.map((feature) => [
            feature,
            false, // Default features to false state.
        ]),
    ) as Record<SpecificElectionFeatures, boolean | string>

    // override toggles if that specific election's toggles are disabled.
    if (election.type === 'federal') {
        for (const feature of specificElectionFeatures) {
            const toggleValue = toggles[`federal-${feature}`]
            if (
                typeof toggleValue === 'boolean' ||
                typeof toggleValue === 'string'
            ) {
                electionToggles[feature] = toggleValue
            }
        }
    } else {
        for (const feature of specificElectionFeatures) {
            const toggleValue = toggles[`${election.state}-${feature}`]
            if (
                typeof toggleValue === 'boolean' ||
                typeof toggleValue === 'string'
            ) {
                electionToggles[feature] = toggleValue
            }
        }
    }

    return electionToggles
}

/**
 * Returns a reactive hook for the toggle states for a specific election
 */
export const useElectionToggles = (election: Election) => {
    const featureToggles = useSelector((state: AppState) => state.toggles)

    const electionToggles = useMemo(
        () => getElectionToggles(election, featureToggles),
        [election, featureToggles],
    )

    return electionToggles
}
