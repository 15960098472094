import {
    AreaDto,
    PartyCode,
    PartyDto,
    SeatDto,
} from '@west-australian-newspapers/election-api-types'

export type InterknowlogyPartyCode = PartyCode | 'COL'

export interface InterknowlogyExtendedParty extends PartyDto {
    combinedCode: InterknowlogyPartyCode | undefined
    combinedPartyCodes: PartyCode[] | undefined
    shortPartyName: string | undefined
}

export interface InterknowlogyArea extends AreaDto {
    parties: InterknowlogyExtendedParty[]
}

export type InterknowlogyData = {
    areas: InterknowlogyArea[]
    seats: SeatDto[]
    lastModified: string
}

export const shortStateNames: Record<string, string> = {
    'Australian Capital Territory': 'ACT',
    'New South Wales': 'NSW',
    'Norhtern Territory': 'NT',
    Queensland: 'QLD',
    'South Australia': 'SA',
    Tasminia: 'TAS',
    Victoria: 'VIC',
    'Western Australia': 'WA',
}

export const shortPartyNames: Record<string, string> = {
    LNP: 'Liberal',
    ALP: 'Labor',
    GRN: 'Greens',
    ONP: 'One Nation',
}

// This is a helper function to transform the data from the interknowlogy API.
export const transformAreaData = (
    data: InterknowlogyData,
): InterknowlogyData => {
    return {
        ...data,
        // Transform the Area data to include the short party names and add the Coalition party
        areas: data.areas.map((area) => {
            let coalitionParty: InterknowlogyExtendedParty | undefined =
                undefined
            const parties = area.parties.map((party) => {
                const shortParty = Object.entries(shortPartyNames).find(
                    (keyValue) => keyValue[0] === party.partyCode,
                ) ?? [party.partyCode, party.partyName]

                return {
                    ...party,
                    shortPartyName: shortParty[1],
                }
            })

            // Filter coalition party data!
            area.parties
                .filter((party) => party.isCoalition)
                .forEach((party) => {
                    if (!coalitionParty) {
                        // If it's not set, then set it
                        coalitionParty = { ...party }
                        coalitionParty.partyName = 'Coalition'
                        coalitionParty.shortPartyName = 'Coalition'
                        coalitionParty.combinedCode = 'COL'
                        coalitionParty.combinedPartyCodes = [party.partyCode]
                    } else {
                        // Else, add the values to the existing object
                        coalitionParty.seatsWon += party.seatsWon
                        coalitionParty.seatsAhead += party.seatsAhead
                        coalitionParty.seatsChanged += party.seatsChanged
                        coalitionParty.votePct += party.votePct // This might need some consideration
                        coalitionParty.voteCount += party.voteCount

                        // Only add the swing percentage if it's not null (component can be disabled if null swing)
                        if (party.swingPct) {
                            ;(coalitionParty.swingPct ?? 0) + party.swingPct // This might also need some consideration
                        }

                        // Add the party code to the combined party codes
                        coalitionParty.combinedPartyCodes?.push(party.partyCode)
                    }
                })

            return {
                ...area,
                // Add the coalition party to the parties array if present
                parties: coalitionParty
                    ? [...parties, coalitionParty]
                    : parties,
            }
        }),
    }
}
