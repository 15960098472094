import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import { ElectionDefinition, StaticRoutes } from '@news-mono/web-common'
import { createElectionRoutes } from './pages/createRoutes'
import { createElectionWidgetTestPage } from './pages/electionWidgetTestPage'

// Currently hardcoded election list.
// To be allow for an easier conversion to a server-resolved route.
export const elections: ElectionDefinition[] = [
    {
        election: {
            type: 'state',
            state: 'wa',
            year: 2025,
            seatsTotal: 59,
            seatsToWin: 30,
            barTotalSeats: 45,
        },
        logo: {
            mobile: require('./assets/wa-election-2025/LogoMobile.svg'),
            desktop: require('./assets/wa-election-2025/LogoDesktop.svg'),
        },
        meta: {
            updatesSocialImage: require('./assets/wa-election-2025/UpdatesSocial.jpg'),
            electoratesSocialImage: require('./assets/wa-election-2025/ElectoratesSocial.jpg'),
            partyTotalsSocialImage: require('./assets/wa-election-2025/PartyTotalsSocial.jpg'),
        },
    },
    {
        election: {
            type: 'federal',
            year: 2025,
            seatsTotal: 150,
            seatsToWin: 76,
            barTotalSeats: 100,
        },
        logo: {
            mobile: require('./assets/federal-election-2025/LogoMobile.svg'),
            desktop: require('./assets/federal-election-2025/LogoDesktop.svg'),
        },
        meta: {
            updatesSocialImage: require('./assets/federal-election-2025/UpdatesSocial.jpg'),
        },
    },
]

const getElectionRoutes = (elections: ElectionDefinition[]) => {
    let pages: StaticRoutes<TheWestSection> = {
        ['/politics/election-widget-test-page']: ({
            getAdTargeting,
            store,
        }) => {
            const enableRoute = isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                'election-widget-test-page',
            )

            if (!enableRoute) {
                return null
            }

            return createElectionWidgetTestPage({
                // Default to the latest election.
                electionDefinition: elections[0],
                getAdTargeting,
            })
        },
    }

    for (const election of elections) {
        pages = {
            ...pages,
            ...createElectionRoutes(election),
        }
    }

    return pages
}

export const electionRoutes = getElectionRoutes(elections)
