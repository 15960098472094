import React, { PropsWithChildren, useCallback } from 'react'
import {
    SeatBarGraphSlidingBar,
    SeatBarGraphContainer,
    SeatBarGraphDivider,
    SeatCandidateContainer,
    SeatCandidateDetails,
    SeatCandidateImage,
    SeatCandidateNameText,
    SeatCandidatePartyText,
    SeatCandidatesContainer,
    SeatDetailsContainer,
    SeatDetailsText,
    SeatHeaderContainer,
    SeatResultsContainer,
    SeatsStatusText,
    SeatStatusContainer,
    SeatVoteCountContainer,
    SeatVoteCountText,
    SeatVotesContainer,
    LoadingPlaceholder,
    SeatContainerEmbed,
    SeatContainerLink,
} from './SeatCard.styled'
import { SeatData, SeatTwoCandidatePreferred } from '../data/election-data'
import { AllEvents, DataLayerEventName } from '@news-mono/web-common'
import { handleImageError } from '../components/helpers'

interface SeatCardProps {
    seat?: SeatData
    isLoading?: boolean
    onEvent?: (event: AllEvents) => void
    electoratePageUrl?: string
    isEmbed?: boolean
}

export const SeatCard: React.FC<SeatCardProps> = ({
    seat,
    isLoading = false,
    onEvent,
    electoratePageUrl,
    isEmbed = false,
}) => {
    const electorateUrl = electoratePageUrl
        ? `${electoratePageUrl}?search=${seat?.seatName.toLowerCase()}&display=expanded`
        : ''
    const handleSeatClick = useCallback(() => {
        onEvent?.({
            type: DataLayerEventName.navClicked,
            originator: 'ElectionTheSeatsWidget',
            payload: {
                navName: 'ElectionSeats',
                navLocation: 'OnPage',
                navText: seat?.seatName,
                navLink: electorateUrl,
            },
        })
    }, [onEvent, electorateUrl, seat])

    if (isLoading || !seat) {
        return <LoadingPlaceholder />
    }

    const {
        seatName,
        state,
        candidates,
        winningParty,
        status,
        winningPartyDarkColor,
    } = seat

    // If the seat is an embed, we don't want to link to the electorate page, so render a div instead of a link
    const SeatContainer = isEmbed ? SeatContainerNoLink : SeatContainerWithLink

    return (
        <SeatContainer
            onClick={handleSeatClick}
            electoratePageUrl={electorateUrl}
        >
            <SeatHeaderContainer>
                <SeatDetailsContainer>
                    <SeatDetailsText variant="name">{seatName}</SeatDetailsText>
                    <SeatDetailsText variant="state">{state}</SeatDetailsText>
                </SeatDetailsContainer>
                {status !== 'Not Called' &&
                    winningParty &&
                    winningPartyDarkColor && (
                        <SeatStatusContainer color={winningPartyDarkColor}>
                            <SeatsStatusText>
                                {winningParty} {status}
                            </SeatsStatusText>
                        </SeatStatusContainer>
                    )}
            </SeatHeaderContainer>
            <SeatResults candidates={candidates} />
        </SeatContainer>
    )
}

type SeatContainerProps = {
    onClick?: () => void
    electoratePageUrl?: string
}

const SeatContainerWithLink = ({
    children,
    onClick,
    electoratePageUrl,
}: PropsWithChildren<SeatContainerProps>) => (
    <SeatContainerLink to={electoratePageUrl!} onClick={onClick}>
        {children}
    </SeatContainerLink>
)
const SeatContainerNoLink = ({
    children,
}: PropsWithChildren<SeatContainerProps>) => (
    <SeatContainerEmbed>{children}</SeatContainerEmbed>
)

const SeatResults: React.FC<{ candidates: SeatTwoCandidatePreferred[] }> = ({
    candidates,
}) => {
    const [candidateOne, candidateTwo] = candidates

    return (
        <SeatResultsContainer>
            <SeatVotesContainer>
                <VoteDisplay
                    votes={candidateOne.primaryVotes}
                    percentage={candidateOne.currentVotesPercentage}
                    color={candidateOne.partyColor.dark}
                    reverse={true}
                />
                <VoteDisplay
                    votes={candidateTwo.primaryVotes}
                    percentage={candidateTwo.currentVotesPercentage}
                    color={candidateTwo.partyColor.dark}
                    reverse={false}
                />
            </SeatVotesContainer>
            <SeatBarGraph
                candidateOneColor={candidateOne.partyColor.primary}
                candidateTwoColor={candidateTwo.partyColor.primary}
                candidateOnePct={candidateOne.currentVotesPercentage}
            ></SeatBarGraph>
            <SeatCandidatesContainer>
                <CandidateDisplay
                    candidateName={candidateOne.candidateName}
                    color={candidateOne.partyColor.dark}
                    partyName={candidateOne.partyName}
                    incumbent={candidateOne.incumbent}
                    imageUrl={candidateOne.imageUrl}
                    reverse={false}
                />
                <CandidateDisplay
                    candidateName={candidateTwo.candidateName}
                    color={candidateTwo.partyColor.dark}
                    partyName={candidateTwo.partyName}
                    incumbent={candidateTwo.incumbent} // NB this should always be false, or something has very wrong with democracy
                    imageUrl={candidateTwo.imageUrl}
                    reverse={true}
                />
            </SeatCandidatesContainer>
        </SeatResultsContainer>
    )
}

const VoteDisplay: React.FC<{
    votes: number
    percentage: number | null
    color: string
    reverse?: boolean
}> = ({ votes, percentage, color, reverse = false }) => (
    <SeatVoteCountContainer color={color}>
        {reverse ? (
            <>
                <SeatVoteCountText variant="percentage">
                    {percentage}%
                </SeatVoteCountText>
                <SeatVoteCountText variant="count">
                    {votes.toLocaleString()}
                </SeatVoteCountText>
            </>
        ) : (
            <>
                <SeatVoteCountText variant="count">
                    {votes.toLocaleString()}
                </SeatVoteCountText>
                <SeatVoteCountText variant="percentage">
                    {percentage}%
                </SeatVoteCountText>
            </>
        )}
    </SeatVoteCountContainer>
)

const CandidateDisplay: React.FC<{
    candidateName: string
    color: string
    partyName: string | null
    incumbent: boolean
    imageUrl: string
    reverse?: boolean
}> = ({
    candidateName,
    color,
    partyName,
    incumbent,
    imageUrl,
    reverse = false,
}) => {
    return(
        <SeatCandidateContainer>
            {reverse ? (
                <>
                    <SeatCandidateDetails reverse={reverse}>
                        <SeatCandidatePartyText color={color}>
                            {partyName}
                        </SeatCandidatePartyText>
                        <SeatCandidateNameText incumbent={incumbent}>
                            {candidateName}
                        </SeatCandidateNameText>
                    </SeatCandidateDetails>
                    <SeatCandidateImage src={imageUrl} alt={candidateName} onError={handleImageError} />
                </>
            ) : (
                <>
                    <SeatCandidateImage src={imageUrl} alt={candidateName} onError={handleImageError} />
                    <SeatCandidateDetails reverse={reverse}>
                        <SeatCandidatePartyText color={color}>
                            {partyName}
                        </SeatCandidatePartyText>
                        <SeatCandidateNameText incumbent={incumbent}>
                            {candidateName}
                        </SeatCandidateNameText>
                    </SeatCandidateDetails>
                </>
            )}
        </SeatCandidateContainer>
    )
}

export const SeatBarGraph: React.FC<{
    candidateOneColor: string
    candidateTwoColor: string
    candidateOnePct: number | null
}> = ({ candidateOneColor, candidateTwoColor, candidateOnePct }) => {
    return (
        <SeatBarGraphContainer>
            <SeatBarGraphSlidingBar
                candidateOneColor={candidateOneColor}
                candidateTwoColor={candidateTwoColor}
                partyOnePct={candidateOnePct}
            />
            <SeatBarGraphDivider />
        </SeatBarGraphContainer>
    )
}
