import styled from '@emotion/styled'
import { ImageLink } from '../../../buttons/ImageLink'
import { breakpoint, breakpointMax } from '../../../__styling/style-functions'
import { calcRem } from '../../../__styling/style-functions/calc-rem'
import { metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../mixins'
import { Headline } from '../../../publication/Headline/Headline'

const { spacing } = metricsPN

const gridGapSize = calcRem(spacing['L'])
export const insetMarginDesktop = calcRem(spacing['2XS'])
export const insetMarginTablet = calcRem(spacing['5XL'])
const insetMarginMob = 0

export const StyledGrid = styled('article')({
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: gridGapSize,
    marginBottom: calcRem(spacing['XL']),
})

export const StyledInsetCell = styled('div')({
    display: 'grid',
    gridGap: gridGapSize,
    [breakpoint('sm')]: {
        padding: `0 ${insetMarginTablet}`,
    },

    [breakpoint('lg')]: {
        padding: 0,
    },
})

export const StyledPNArticleContent = styled('div')({
    /*
        - Adds the horizontal padding to the article content
        - Excludes the figure component so we can center images & video of unknown width
        but also have the image asset full bleed to the edge on desktop.
    */
    width: '100%',

    '&>div > *:not(figure):not(section)': {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: insetMarginMob,
        paddingRight: insetMarginMob,

        [breakpoint('sm')]: {
            paddingLeft: insetMarginTablet,
            paddingRight: insetMarginTablet,
        },

        [breakpoint('lg')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },

    '& *:last-child:not(h2)': {
        marginBottom: 0,
    },
})

export const StyledImageLinkFullsize = styled(ImageLink)({
    [breakpointMax('xxs')]: {
        display: 'none',
    },
})
export const StyledImageLinkMobile = styled(ImageLink)({
    [breakpoint('xxs')]: {
        display: 'none',
    },
})

export const StyledClassification = styled('span')({
    ...perthNowNGNTextMixin.label.S.medium,

    [breakpoint('sm')]: {
        ...perthNowNGNTextMixin.label.M.medium,
    },
})

export const StyledHeadline = styled(Headline)({
    ...perthNowNGNTextMixin.title.L.bold,
    [breakpoint('sm')]: {
        ...perthNowNGNTextMixin.title.XL.bold,
    },
})

export const StyledPNSharingControls = styled('div')({
    display: 'flex',
    gap: calcRem(spacing['2XS']),
})
