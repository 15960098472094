import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { ContainerWidth, ThemeMargins } from '../../__styling/settings/metrics'
import { ContentWrapper } from '../ContentWrapper/ContentWrapper'
import { BaseCompositionProps } from '../types'
import { Athena, SidebarOptions } from './Athena'

export interface AthenaRouteProps extends BaseCompositionProps {
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    stackSidebarContent?: boolean
    sidebarOptions: SidebarOptions
    elementType: 'div' | 'article'
    hasHorizontalGutters?: boolean
    hasVerticalPadding?: boolean
}

export const AthenaRegistration = createRegisterableComposition<
    'main' | 'sidebar'
>()('athena', (contentAreas, props: AthenaRouteProps) => (
    <ContentWrapper
        stretchColumns={props.stretchColumns}
        stretchSelf={props.stretchSelf}
    >
        <Athena
            {...props}
            mainContent={contentAreas.main}
            sidebarContent={contentAreas.sidebar}
        />
    </ContentWrapper>
))
