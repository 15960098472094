import {
    createCollectionAvailableEvent,
    isPublicationCardItemWithVideo,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import React, { useCallback } from 'react'
import { CardBodyWrapper, LargeCardMedia, Byline, Teaser } from '../components'
import {
    StyledHeadline,
    LeadCardContainer,
    CardLinkWrapper,
    StyledCardContentContainer,
} from './LeadCard.styled'
import { getByline, getTopicLink } from '../utils'
import { BaseCardProps, cardViewabilityDebug } from '../Card'
import { getEditorialType, getFundingType } from '../../../templates'
import { TNLiveComponent } from '../../../content'
import { useImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { useProduct } from '../../../__product'
import { FundingLabel } from '../../../advertising/FundingLabel/FundingLabel'
import { BylineFundingTypeWrapper } from '../../../publication/Byline/components/TheNightly/Byline.styled'
import { StyledReadMoreWrapper } from '../components/card-body/ReadMore.styled'
import { CardContentWithLiveBlogs } from '../../LiveBlogs'
import { ReadMoreWithLiveBlogs } from '../components/ReadMoreWithLiveBlogs'

export interface LeadCardProps extends BaseCardProps<'lead-card'> {}

export const LeadCard = ({
    item,
    onEvent,
    adTargeting,
    imageFetchPriority,
    disableImageLazyLoad,
}: Omit<LeadCardProps, 'kind'>) => {
    const classificationEnabled = true

    const inlineVideoEnabled = useFeature('tn-inline-video-enabled')
    const product = useProduct()
    const { loaded: isLoaded } = item
    const result = isLoaded ? item.result : undefined
    const articleLink = toLinkWithHint(item)
    const byline = getByline(item)
    const topicLink = getTopicLink(item)
    const editorialType = isLoaded
        ? getEditorialType(item.result, classificationEnabled)
        : undefined
    const fundingType = isLoaded
        ? getFundingType(item.result, classificationEnabled)
        : undefined

    const showNormalClassification = !!editorialType
    const willPlayVideoInline =
        isLoaded && inlineVideoEnabled
            ? isPublicationCardItemWithVideo(result!)
            : false

    // Impression Available Function
    const availableFunction = useCallback(() => {
        if (!isLoaded || result === undefined) {
            console.warn(
                'Available Function should never be called when loading is true',
            )
            return
        }

        // Add a debug for viewability!
        cardViewabilityDebug(
            'LeadCard',
            `in frame and has been viewed with a size of ${1}`,
        )

        // Send an onEvent when the items are available in view
        onEvent(
            createCollectionAvailableEvent(
                [result],
                'LeadCard',
                product,
                onEvent,
            ),
        )
    }, [isLoaded, onEvent, product, result])

    // Impression Available Ref
    const impressionAvailableRef = useImpressionAvailable({
        loading: !isLoaded,
        available: availableFunction,
        debug: cardViewabilityDebug.extend('lead-card'),
    })

    return (
        <LeadCardContainer ref={impressionAvailableRef}>
            {willPlayVideoInline ? (
                <LargeCardMedia
                    item={item}
                    onEvent={onEvent}
                    willPlayVideoInline={willPlayVideoInline}
                    adUnitPath={adTargeting?.adUnitPath}
                    disableImageLazyLoad={disableImageLazyLoad}
                    imageFetchPriority={imageFetchPriority}
                />
            ) : (
                <CardLinkWrapper to={articleLink}>
                    {editorialType && !showNormalClassification && (
                        <TNLiveComponent
                            overlayOnImage={true}
                            showBackground={true}
                        />
                    )}
                    <LargeCardMedia
                        item={item}
                        onEvent={onEvent}
                        willPlayVideoInline={willPlayVideoInline}
                        adUnitPath={adTargeting?.adUnitPath}
                        disableImageLazyLoad={disableImageLazyLoad}
                        imageFetchPriority={imageFetchPriority}
                    />
                </CardLinkWrapper>
            )}
            {result && (
                <CardBodyWrapper>
                    <BylineFundingTypeWrapper>
                        <Byline
                            editorialType={editorialType}
                            topicLink={topicLink}
                            topicTitle={result.primaryTopic.title}
                            bylineText={byline.text}
                            bylineLink={byline.link}
                            allowCommenting={result.allowCommenting}
                            articleUrl={result._self}
                        />
                        {fundingType && (
                            <FundingLabel
                                fundingType={fundingType}
                                linkToDisclaimer={true}
                            >
                                {fundingType}
                            </FundingLabel>
                        )}
                    </BylineFundingTypeWrapper>
                    <StyledCardContentContainer>
                        <CardLinkWrapper to={articleLink}>
                            <StyledHeadline>
                                {result.shortHeadline}
                            </StyledHeadline>
                        </CardLinkWrapper>
                        <CardContentWithLiveBlogs
                            result={result}
                            getLinkComponentWithChildren={(props) => (
                                <CardLinkWrapper {...props} />
                            )}
                            DefaultComponent={<Teaser>{result.teaser}</Teaser>}
                            onEvent={onEvent}
                        />
                        <CardLinkWrapper to={articleLink}>
                            <StyledReadMoreWrapper>
                                <ReadMoreWithLiveBlogs
                                    readTime={result.readTimeMinutes}
                                    postCount={result.entries?.total}
                                />
                            </StyledReadMoreWrapper>
                        </CardLinkWrapper>
                    </StyledCardContentContainer>
                </CardBodyWrapper>
            )}
        </LeadCardContainer>
    )
}
