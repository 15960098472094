/** The logo will fallback to mobile if desktop is not provided. */
export type ElectionLogo = {
    /** An image uri. */
    mobile: string
    /** An image uri. */
    desktop?: string
    /** A human readable description for screen-readers. */
    alt?: string
}

// The goal here is to ONLY have properties inherent to the election in this type, with nothing 'extra' (images, meta, component-stuff) bundled into it.

export type StateElection = {
    type: 'state'
    year: number
    state: 'act' | 'nsw' | 'nt' | 'qld' | 'sa' | 'tas' | 'vic' | 'wa'
    seatsTotal: number
    seatsToWin: number
    barTotalSeats: number
}

export type FederalElection = {
    type: 'federal'
    year: number
    seatsTotal: number
    seatsToWin: number
    barTotalSeats: number
}

export type Election = StateElection | FederalElection

/** Contains all the data relevant to an election. */
export type ElectionDefinition = {
    election: Election
    logo: ElectionLogo
    meta: {
        /** 2400x1260px image URIs. */
        updatesSocialImage?: string
        electoratesSocialImage?: string
        partyTotalsSocialImage?: string
    }
}

/**
 * Returns the election id, provided an election object.
 * Used in the url as a slug.
 */
export const getElectionId = (election: Election) => {
    if (election.type === 'federal') {
        return `federal-election-${election.year}`
    } else {
        return `${election.state}-election-${election.year}`
    }
}

/**
 * If the election is a federal election.
 */
export const isFederalElection = (
    electionData: Election,
): electionData is FederalElection => {
    return electionData.type === 'federal'
}

/**
 * If the election is a state election.
 */
export const isStateElection = (
    electionData: Election,
): electionData is StateElection => {
    return electionData.type === 'state'
}
