import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { Link } from 'react-router-dom'
import {
    mapTitleMixin,
    perthNowNGNTextMixin,
} from '../../../mixins/TextMixin/TextMixin.theme'
import {
    Breakpoints,
    calcRem,
    visuallyhidden,
    breakpoint,
    visuallyhiddenOff,
    easing,
} from '../../../__styling'
import { zIndex } from '../../../__styling/settings/z-index'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'

export interface SectionHeaderWrapperProps {
    marginBottom: number
    invisibleHeaderBreakpoint?: keyof Breakpoints
}

export const SectionHeaderContainer = styled.div<SectionHeaderWrapperProps>(
    ({ invisibleHeaderBreakpoint, marginBottom }) => {
        return [
            {
                display: 'flex',
                flex: '1 0 auto',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                maxWidth: '100%',
                marginBottom: marginBottom,
                transition: 'margin 0.25s, padding 0.25s',
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
                flexDirection: 'column',
                gap: calcRem(metricsPN.spacing['4XS']),
            },
            invisibleHeaderBreakpoint !== undefined && {
                ...visuallyhidden,

                [breakpoint(invisibleHeaderBreakpoint || 'md')]: {
                    ...visuallyhiddenOff,
                },
            },
        ]
    },
)
export interface StyledHeadingProps {
    mainSection?: boolean
    hasPadding?: boolean
    hasLogo?: boolean
}

export const Heading = styled.h2<StyledHeadingProps>(
    ({ mainSection, hasLogo }) => {
        const headerMixin = mapTitleMixin(mainSection ? '2XL' : 'M')

        return [
            {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                margin: 0,
                position: 'relative',
                color: colorsPN.text.brand,
                flex: 1,
                svg: hasLogo
                    ? {
                          height: calcRem(22),
                          marginLeft: calcRem(3),
                          transform: 'translateY(1px)',
                      }
                    : undefined,
                ...headerMixin.small.bold,
                [breakpoint('sm')]: headerMixin.large.bold,
            },
        ]
    },
)

export const HeadingWrapper = styled.div(() => {
    return {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        gap: calcRem(metricsPN.spacing['4XS']),
        alignItems: 'center',
        minHeight: calcRem(44),
        width: '100%',
        justifyContent: 'space-between',
    }
})

export const HeadingLink = styled(WebLink)<{ hideOnMobile?: boolean }>(
    ({ hideOnMobile = 'false' }) => {
        return {
            textDecoration: 'none',
            zIndex: zIndex.common.link,
            cursor: 'pointer',
            transition: `background-color ${easing.easeOut.fast}`,
            color: colorsPN.text.brand,
            display: hideOnMobile ? 'none' : 'flex',
            alignItems: 'center',
            ...perthNowNGNTextMixin.label.L.medium,

            '&:focus-visible': {
                outline: 'none',
                textDecoration: 'underline',
            },

            '&:hover': {
                textDecoration: 'underline',
            },

            [breakpoint('md')]: {
                display: 'flex',
            },
        }
    },
)

export const StyledNav = styled.nav(() => {
    return {
        width: '100%',
        overflowX: 'scroll',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [breakpoint('md')]: {
            overflowX: 'unset',
        },
    }
})

export const NavLinkList = styled.ul(({}) => {
    return {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        width: '100%',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        display: 'flex',
        gap: `0 ${calcRem(metricsPN.spacing['XL'])}`,
        flexWrap: 'unset',
        paddingTop: calcRem(metricsPN.spacing['3XS']),
        paddingBottom: calcRem(metricsPN.spacing['3XS']),

        [breakpoint('md')]: {
            flexWrap: 'wrap',
            overflowX: 'unset',
        },
    }
})

export const NavLinkWrapper = styled.li({
    width: 'auto',
    flex: '0 1 auto',
    textAlign: 'center',
})

export const LocalNavLink = styled(Link)(({}) => {
    return [
        {
            display: 'block',
            whiteSpace: 'nowrap',
            position: 'relative',
            textDecoration: 'none',
            height: '100%',
            paddingTop: calcRem(metricsPN.spacing['3XS']),
            paddingBottom: calcRem(metricsPN.spacing['3XS']),
            zIndex: zIndex.common.link,

            '&:focus-visible': {
                textDecoration: 'underline',
                outline: 'revert',
                outlineOffset: calcRem(metricsPN.spacing['4XS']),
                borderRadius: `${metricsPN.radius['S']}px`,
            },

            color: colorsPN.text.primary,

            '&:hover': {
                textDecoration: 'underline',
            },

            '&:active': {
                color: colorsPN.text.brand,
            },
        },
        perthNowNGNTextMixin.label.L.medium,
    ]
})

export const WebNavLink = LocalNavLink.withComponent(WebLink)
