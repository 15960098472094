import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { calcRem, breakpoint } from '../../../__styling'

export const StyledLatestLiveBlogMilestonesContainer = styled.div(
    ({ theme }) => [
        {
            display: 'block',
        },
        // Styling for TheNightly specifically
        theme.kind === 'thenightly' && {
            margin: calcRem(16, 0),
            padding: calcRem(16),
            background: tokens.thenightly.colors.palette.neutral[10],
            borderRadius: calcRem(12),

            [breakpoint('sm')]: {
                padding: calcRem(16, 24),
            },
        },
        // Styling for TheWest specifically
        theme.kind === 'thewest' && {
            padding: 0,
            margin: 0,
            width: '100%',
            background: 'transparent',
        },
    ],
)

// Row Styling

export const StyledLiveBlogMilestoneRow = styled.div({
    display: 'block',
    gap: calcRem(8),
    alignSelf: 'stretch',
})
export const StyledLiveBlogBulletPoint = styled.div({
    display: 'block',
    width: calcRem(8),
    height: '100%',
    padding: 0,
    margin: '0 auto',
    position: 'absolute',
})
export const StyledLiveBlogBulletMilestoneMark = styled.div({
    display: 'block',
    margin: '0 auto',
    width: calcRem(1),
    height: '100%',
    position: 'relative',

    background: tokens.thenightly.colors.palette.neutral[20],
})
export const StyledLiveBlogBulletPointContainer = styled.div<{
    isVrTest?: boolean
}>(({ theme, isVrTest = false }) => [
    {
        display: 'block',
        position: 'absolute',
        zIndex: 1,
        width: 'fit-content',
        height: '100%',
    },
    // TheWest specific styling
    theme.kind === 'thewest' && {
        marginTop: isVrTest ? calcRem(5) : calcRem(-4),
    },
])

// Row Content Styling

export const StyledLiveBlogRowContentContainer = styled.div<{
    isLastRow: boolean
}>(({ isLastRow }) => ({
    display: 'block',
    paddingBottom: isLastRow ? 0 : calcRem(8),
    marginLeft: calcRem(16),
    gap: calcRem(4),
}))
export const StyledLiveBlogRowTitle = styled.h3(({ theme }) => [
    {
        color: tokens.thenightly.colors.palette.neutral[100],
        margin: 0,

        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
        textDecorationSkipInk: 'none',
        textDecorationThickness: 'auto',

        textUnderlineOffset: 'auto',
        textUnderlinePosition: 'from-font',
    },
    // TheNightly text styling
    theme.kind === 'thenightly' && textMixin(theme, 'body-alt'),
    // TheWest text styling
    theme.kind === 'thewest' && {
        ...textMixin(theme, 'head-9'),
        fontVariantNumeric: 'lining-nums proportional-nums',
        textUnderlinePosition: 'auto',
    },
])

export const StyledLiveBlogRowTimestamp = styled.p(({ theme }) => [
    {
        color: tokens.thenightly.colors.palette.neutral[70],
        margin: 0,
    },
    theme.kind === 'thenightly' && textMixin(theme, 'byline-secondary'),
    theme.kind === 'thewest' && textMixin(theme, 'head-9'),
])
