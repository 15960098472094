import React from 'react'
import { useFeatureValue } from '@news-mono/web-common'
import { ElectionTitle, StyledElectionTitleContainer } from '../components'
import {
    InterknowlogyData,
    SeatData,
    getSeatsData,
    transformAreaData,
} from '../data'
import { SeatCard } from '../SeatCard/SeatCard'
import { TheSeatEmbedWidget } from '../TheSeatEmbedWidget/TheSeatEmbedWidget'
import { TransformedData } from '@west-australian-newspapers/election-api-types'
import { MaybeLoaded } from 'json-react-layouts-data-loader'

/**
 * @returns a collection component to display all electorate embeds in one list
 */
interface TheSeatEmbedCollectionWidgetProps {
    data: MaybeLoaded<TransformedData | undefined>
    imageBaseUrl?: string
}

export const TheSeatEmbedCollectionWidget = ({
    data,
    imageBaseUrl,
}: TheSeatEmbedCollectionWidgetProps) => {
    const electionId: number = useFeatureValue('2025-election-id') || 632

    const initialData = data.loaded
        ? getSeatsData(
              transformAreaData(data.result as InterknowlogyData),
              imageBaseUrl,
              64,
          )
        : undefined

    // get the seat data for the provided electrorate name
    const [seatData, setSeatData] = React.useState<SeatData[] | undefined>(
        initialData,
    )

    React.useEffect(() => {
        const load = async () => {
            try {
                if (data.loaded && data.result) {
                    const interknowlogyData = transformAreaData(
                        data.result as InterknowlogyData,
                    )
                    const seats = getSeatsData(
                        interknowlogyData,
                        imageBaseUrl,
                        64,
                    )
                    setSeatData(seats)
                }
            } catch (error) {
                console.error('Error loading interknowlogy data:', error)
            }
        }

        load()
    }, [data, electionId, imageBaseUrl])

    return (
        <>
            <StyledElectionTitleContainer>
                <ElectionTitle>The Seat Embeds</ElectionTitle>
            </StyledElectionTitleContainer>
            {seatData !== undefined
                ? seatData
                      .slice(0, 6)
                      .map((seat) => (
                          <TheSeatEmbedWidget
                              key={seat.seatId}
                              providedData={seat}
                              imageBaseUrl={imageBaseUrl}
                          />
                      ))
                : Array(6) // Render 6 skeletons while loading
                      .fill(0)
                      .map((_, index) => (
                          <TheSeatEmbedWidget
                              key={`placeholder-${index}`}
                              imageBaseUrl={imageBaseUrl}
                          />
                      ))}
        </>
    )
}
