import {
    createRegisterableComponentWithData,
    ElectionDataLoader,
} from '@news-mono/web-common'
import React from 'react'
import { FullElectorateWidget } from './FullElectorateWidget'

export const FullElectorateWidgetRegistration =
    createRegisterableComponentWithData(
        'election-full-electorate-widget',
        ElectionDataLoader,
        (_, data, services) => {
            return (
                <FullElectorateWidget data={data} onEvent={services.onEvent} imageBaseUrl={services.config.imageBaseUrl}/>
            )
        },
    )
