import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import {
    ElectionDefinition,
    getElectionId,
    getElectionToggles,
    getResolvedTopicPageMeta,
    StaticRoutes,
} from '@news-mono/web-common'
import { createElectorateEmbedPage } from '../embeds/createElectorateEmbedPage'
import { createElectionElectoratePage } from './createElectionElectoratePage'
import { createElectionLandingPage } from './createElectionLandingPage'
import { createElectionPartyTotalsPage } from './createElectionPartyTotalsPage'
import { getTopicPageRouteInfo } from '../../topic/get-topic-page-route-info'

/**
 * Creates the routes declarations for an election.
 */
export const createElectionRoutes = (
    electionDefinition: ElectionDefinition,
): StaticRoutes<TheWestSection> => {
    return {
        [`/politics/${getElectionId(electionDefinition.election)}`]: ({
            getAdTargeting,
            store,
            resolution,
            config,
        }) => {
            // Override meta with that from taxonomy if provided.
            const topicMeta = getResolvedTopicPageMeta(resolution)

            const toggles = store.getState().toggles

            const { 'election-landing-page-route': isLandingPageEnabled } =
                getElectionToggles(electionDefinition.election, toggles)

            if (!isLandingPageEnabled) {
                // Fallback to default topic page layout if available.
                if (
                    resolution.type === 'static' &&
                    resolution.resolution?.type === 'topic'
                ) {
                    return getTopicPageRouteInfo({
                        publicationKind: resolution.resolution.publicationKind,
                        topic: resolution.resolution.topic,
                        config,
                        section: resolution.resolution
                            .section as TheWestSection,
                        getAdTargeting,
                        classification: resolution.resolution.classification,
                    })
                }

                return null
            }

            return createElectionLandingPage({
                getAdTargeting,
                electionDefinition: electionDefinition,
                toggles,
                heading: topicMeta?.seoTitle,
                description: topicMeta?.seoDescription,
            })
        },
        [`/embeds/${getElectionId(electionDefinition.election)}/electorate`]: ({
            getAdTargeting,
            store,
        }) => {
            const enableRoute = isFeatureEnabled(
                toFeatureState(store.getState().toggles),
                'election-widget-preview-page',
            )

            if (!enableRoute) {
                return null
            }

            let heading
            let description

            if (electionDefinition.election.type === 'federal') {
                heading = `${electionDefinition.election.year} Federal Election Embed`
                description = 'An embed for the federal election electorate.'
            } else {
                heading = `${
                    electionDefinition.election.year
                } ${electionDefinition.election.state.toUpperCase()} State Election Embed`
                description = 'An embed for the state election electorate.'
            }

            return createElectorateEmbedPage({
                heading,
                description,
                getAdTargeting,
            })
        },
        [`/politics/${getElectionId(electionDefinition.election)}/electorates`]:
            ({ getAdTargeting, store, config }) => {
                const {
                    'election-electorate-results-route': isElectoratesEnabled,
                } = getElectionToggles(
                    electionDefinition.election,
                    store.getState().toggles,
                )

                if (!isElectoratesEnabled) {
                    return null
                }

                return createElectionElectoratePage({
                    heading: 'WA election 2025 electorate results',
                    description:
                        'Full WA election 2025 results: See all the votes and how every seat across Western Australia was won in the 2025 State election.',
                    getAdTargeting,
                    electionDefinition: electionDefinition,
                })
            },
        [`/politics/${getElectionId(
            electionDefinition.election,
        )}/party-totals`]: ({ getAdTargeting, store, resolution }) => {
            // Override meta with that from taxonomy if provided.
            const topicMeta = getResolvedTopicPageMeta(resolution)

            const toggles = store.getState().toggles

            const { 'election-party-totals-route': isPartyTotalsEnabled } =
                getElectionToggles(electionDefinition.election, toggles)

            if (!isPartyTotalsEnabled) {
                return null
            }

            return createElectionPartyTotalsPage({
                electionDefinition,
                heading: topicMeta?.seoTitle,
                description: topicMeta?.seoDescription,
                getAdTargeting,
            })
        },
    }
}
