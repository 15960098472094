import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import { GetRouteAdTargeting, PageType } from '@news-mono/web-common'
import { layout } from '../../../App.routing'

type CreateElectorateEmbedPageArgs = {
    heading: string
    description: string
    getAdTargeting: GetRouteAdTargeting
}

export const createElectorateEmbedPage = ({
    heading,
    description,
    getAdTargeting,
}: CreateElectorateEmbedPageArgs): PageType<TheWestSection> => {
    return {
        kind: 'page',
        heading,
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting: getAdTargeting('home', 'default', 'home'),
        pageMeta: {
            description,
        },
        socialMeta: {
            description,
        },
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'election-seat-embed-widget',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'election',
                                    electionId: 632,
                                    pageSize: 1,
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
