import { SeatDto } from '@west-australian-newspapers/election-api-types'
import { Election } from '@news-mono/web-common'
import {
    InterknowlogyData,
    InterknowlogyExtendedParty,
    shortPartyNames,
    shortStateNames,
} from '.'
import placeholderImg from '../components/assets/placeholder-circle-min.png'

// Top Party Data for the provided Election Data

export interface TopPartyDataProps {
    partiesToShow: number
    dataFeed: InterknowlogyData
    sortByPredicted?: boolean
}

export interface TopParty {
    code: string
    name: string
    seats: number
    seatsPredicted: number
    colors: {
        primary: string
        pale10: string
        pale20: string
        light: string
        dark: string
    }
    swingPct: number | null
    seatsChanged: number
    votePct: number
    voteCount: number
}
export interface CombinedTopParty extends TopParty {
    combinedPartyCodes: string[]
}
export interface TopPartyResults {
    parties: TopParty[]
    sort: 'ascending' | 'descending'
    pctCounted: number
    lastUpdated: string
}

type PartyColors = {
    primary: string
    pale10: string
    pale20: string
    light: string
    dark: string
}

type PartyPalette = Record<string, PartyColors>

export const partyColors: PartyPalette = {
    //Coalition, used in The Race component
    COL: {
        primary: '#0008E1',
        pale10: '#0008E11A',
        pale20: '#0008E133',
        dark: '#0005A5',
        light: '#878BFF',
    },
    // Liberal-National Party, used in The Seats Widget
    LNP: {
        primary: '#0008E1',
        pale10: '#0008E11A',
        pale20: '#0008E133',
        dark: '#0005A5',
        light: '#878BFF',
    },
    // Australian Labor Party
    ALP: {
        primary: '#E10004',
        pale10: '#E100041A',
        pale20: '#E1000433',
        dark: '#870002',
        light: '#FF696C',
    },
    //Greens
    GRN: {
        primary: '#76B500',
        pale10: '#76B5001A',
        pale20: '#76B50033',
        dark: '#466C00',
        light: '#CBFF6A',
    },
    //Independent
    IND: {
        primary: '#00CBBD',
        pale10: '#00CBBD1A',
        pale20: '#00CBBD33',
        dark: '#006660',
        light: '#67FFF5',
    },
    //National Party
    NP: {
        primary: '#3B794A',
        pale10: '#3B794A1A',
        pale20: '#3B794A33',
        dark: '#0A531C',
        light: '#64E082',
    },
    //Other Parties
    OTH: {
        primary: '#8E6B8F',
        pale10: '#8E6B8F1A',
        pale20: '#8E6B8F33',
        dark: '#6B2A6D',
        light: '#E58FE7',
    },
}

export const getElectionPartyData = ({
    partiesToShow,
    dataFeed,
    sortByPredicted = true,
}: TopPartyDataProps): TopPartyResults => {
    const sort = 'descending'

    // TODO: Do we need to filter to the specific area
    // Create a deep copy of the parties array to avoid mutations
    let parties = dataFeed.areas[0].parties

    const coalitionPartyResults = getCombinedTopParty(
        'COL',
        'Coalition',
        parties,
        (party) =>
            party.combinedPartyCodes &&
            party.isCoalition &&
            party.combinedCode === 'COL',
        getPartyColors('COL'),
        true,
    )

    // If results were found, let's clear out the other coalition parties
    if (coalitionPartyResults) {
        parties = coalitionPartyResults.parties.filter(
            (party) => !party.isCoalition,
        )
    }

    // Combine all the other parties into the party code provided!
    const otherPartyResults = getCombinedTopParty(
        'OTH',
        'Other',
        parties,
        (party) =>
            party.partyCode === 'OTH' ||
            Object.keys(partyColors).indexOf(party.partyCode) === -1,
        getPartyColors('OTH'),
        true,
    )

    if (otherPartyResults) {
        parties = otherPartyResults.parties
    }

    // Now let's map all the other parties, yay!
    const mappedParties: TopParty[] = parties.map((party) => {
        return {
            code: party.partyCode,
            name: party.shortPartyName ?? party.partyName,
            seats: party.seatsWon,
            seatsPredicted: party.seatsWon + party.seatsAhead,
            colors: getPartyColors(party.partyCode) || '#000000',
            swingPct: party.swingPct,
            seatsChanged: party.seatsChanged,
            votePct: party.votePct,
            voteCount: party.voteCount,
        }
    })

    // Now add the extra data, if needed!
    if (coalitionPartyResults) {
        mappedParties.push(coalitionPartyResults.topParty)
    }
    if (otherPartyResults) {
        mappedParties.push(otherPartyResults.topParty)
    }

    return {
        // Now lets sort and return the data!
        parties: mappedParties
            .sort((a, b) => sortTopPartyResults(sort, sortByPredicted, a, b))
            .slice(0, partiesToShow),
        sort: sort,
        pctCounted: dataFeed.areas[0].pctCounted,
        lastUpdated: dataFeed.lastModified,
    }
}

export const getCombinedTopParty = (
    partyCode: string,
    partyName: string,
    parties: InterknowlogyExtendedParty[],
    predicate: (
        value: InterknowlogyExtendedParty,
        index: number,
        obj: InterknowlogyExtendedParty[],
    ) => unknown,
    colors: PartyColors,
    clear?: boolean,
):
    | { topParty: CombinedTopParty; parties: InterknowlogyExtendedParty[] }
    | undefined => {
    const topParty: CombinedTopParty = {
        code: partyCode,
        name: partyName,
        seats: 0,
        seatsPredicted: 0,
        colors,
        combinedPartyCodes: [],
        swingPct: null,
        seatsChanged: 0,
        votePct: 0,
        voteCount: 0,
    }

    // Combine any parties that match the predicate into 1 party
    parties.filter(predicate).forEach((party) => {
        topParty.seats += party.seatsWon
        topParty.seatsPredicted += party.seatsWon + party.seatsAhead
        topParty.combinedPartyCodes.push(party.partyCode)
        if (party.swingPct !== null) {
            // If we have an incoming swingPct, but the topParty is null, fallback to 0 then add it
            topParty.swingPct = (topParty.swingPct || 0) + party.swingPct
        }
        topParty.seatsChanged += party.seatsChanged
        topParty.votePct += party.votePct
        topParty.voteCount += party.voteCount
    })

    // if we're clearing, then run this!
    if (clear === true) {
        parties = parties.filter((party) => !predicate(party, 0, parties))
    }

    if (topParty.combinedPartyCodes.length > 0) {
        // Results found, return them!
        return {
            parties,
            topParty,
        }
    }

    return undefined
}

export const sortTopPartyResults = (
    sort: string,
    sortByPredicted: boolean,
    a: TopParty,
    b: TopParty,
): number => {
    const aSeat = sortByPredicted ? a.seatsPredicted : a.seats
    const bSeat = sortByPredicted ? b.seatsPredicted : b.seats

    return sort === 'descending' ? bSeat - aSeat : aSeat - bSeat
}

// Seats Data

export interface SeatData {
    seatId: string
    seatName: string
    incumbentParty: string | null
    state: string
    candidates: SeatTwoCandidatePreferred[]
    allCandidates: SeatAllCandidates[]
    winningParty: string | undefined
    winningPartyDarkColor: string | undefined
    status: 'Retain' | 'Gain' | 'Not Called'
}

export interface SeatAllCandidates {
    currentVotesPercentage: number | null
    primaryVotes: number
    partyColor: PartyColors
    imageUrl: string
    candidateName: string
    partyName: string | null
    partyCode: string
    swing: number | null
}

export interface SeatTwoCandidatePreferred extends SeatAllCandidates {
    incumbent: boolean
}

export const getSeatsData = (
    dataFeed: InterknowlogyData,
    imageBaseUrl?: string,
    imageWidth?: number,
): SeatData[] => {
    const seats: SeatDto[] = dataFeed.seats

    return seats.map((seat) => {
        const state = shortStateNames[seat.state] || seat.state

        const { incumbentParty, seatName, seatId } = seat

        const winningParty = seat.twoPartyPreferred.find(
            (party) => party.winner,
        )

        const winningPartyCode = winningParty?.partyCode

        const status = determineSeatStatus(seat)

        const candidates: SeatTwoCandidatePreferred[] =
            seat.twoPartyPreferred.map((candidate) => {
                const candidateDetails = seat.candidates.find(
                    (c) => candidate.candidateId === c.candidateId,
                )

                const assetImageUrl =
                    imageBaseUrl && candidateDetails?.photoUrl
                        ? `${imageBaseUrl}/${candidateDetails?.photoUrl}?imwidth=${imageWidth}`
                        : placeholderImg

                return {
                    currentVotesPercentage: candidate.tcpVotesPctCurrent
                        ? Math.round(candidate.tcpVotesPctCurrent)
                        : null, //Round 57.43% to 57%
                    primaryVotes: candidate.primaryVotes,
                    partyColor: getPartyColors(candidate.partyCode),
                    imageUrl: assetImageUrl,
                    candidateName: `${
                        candidate.candidateFirstName
                    } ${normalizeName(candidate.candidateLastName)}`,
                    partyName: candidate.candidatePartyCode
                        ? shortPartyNames[candidate.candidatePartyCode] ??
                          candidate.candidatePartyName
                        : candidate.candidatePartyName,
                    partyCode: candidate.partyCode,
                    winner: candidate.winner,
                    incumbent: candidate.incumbent,
                    swing: candidateDetails ? candidateDetails.swingPct : null,
                }
            })

        /**
         * Sort the candidates so that if either candidate is the incumbent,
         *  they are always first in the list
         */

        const sortedCandidates = candidates.sort((a, b) => {
            if (a.incumbent && !b.incumbent) return -1
            if (!a.incumbent && b.incumbent) return 1
            return 0 // Maintain order if neither is incumbent
        })

        const allCandidates: SeatAllCandidates[] = seat.candidates.map(
            (candidate) => {
                const candidateDetails = seat.candidates.find(
                    (c) => candidate.candidateId === c.candidateId,
                )

                const assetImageUrl =
                    imageBaseUrl && candidateDetails?.photoUrl
                        ? `${imageBaseUrl}/${candidateDetails?.photoUrl}?imwidth=${imageWidth}`
                        : placeholderImg

                return {
                    currentVotesPercentage:
                        Math.round(candidate.primaryVotesPct * 10) / 10, //Round 57.43% to 57%
                    primaryVotes: candidate.primaryVotes,
                    partyColor: getPartyColors(candidate.partyCode),
                    imageUrl: assetImageUrl,
                    candidateName: `${candidate.firstName} ${normalizeName(
                        candidate.lastName,
                    )}`,
                    partyName:
                        shortPartyNames[candidate.partyCode] ||
                        candidate.partyName,
                    partyCode: candidate.partyCode,
                    swing: candidate.swingPct
                        ? Math.round(candidate.swingPct * 10) / 10
                        : null,
                }
            },
        )
        const sortedAllCandidates = allCandidates.sort((a, b) => {
            return a.primaryVotes > b.primaryVotes ? -1 : 1
        })

        return {
            seatId,
            seatName,
            incumbentParty,
            state,
            candidates: sortedCandidates,
            winningParty: winningPartyCode,
            winningPartyDarkColor: getPartyColors(winningPartyCode).dark,
            status,
            allCandidates: sortedAllCandidates,
        }
    })
}

// Change "NAME" to "Name" and "NAE NAE" to "Nae Nae"
const normalizeName = (name: string) =>
    name
        .split(' ')
        .map(
            (part) =>
                part.charAt(0).toUpperCase() + part.slice(1).toLowerCase(),
        )
        .join(' ')

function determineSeatStatus(seat: SeatDto): 'Retain' | 'Gain' | 'Not Called' {
    const winningParty = seat.twoPartyPreferred.find((party) => party.winner)

    if (!winningParty) {
        return 'Not Called'
    }

    return winningParty.candidatePartyName === seat.incumbentParty
        ? 'Retain'
        : 'Gain'
}

const getPartyColors = (partyCode: string | undefined) =>
    partyColors[partyCode ?? 'OTH'] || partyColors.OTH
