import {
    ElectionDefinition,
    getElectionId,
    getElectionToggles,
    GetRouteAdTargeting,
    getSocialImageMeta,
    PageType,
    TogglesReduxState,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import {
    metrics,
    calcRem,
    theWestTextOnlySectionHeaderOverride,
} from '@news-mono/component-library'
import { TheWestSection } from '@news-mono/common'
import { getElection2025Topic } from './createElectionLandingPage'

type CreateElectionPartyTotalsPageArgs = {
    getAdTargeting: GetRouteAdTargeting
    electionDefinition: ElectionDefinition
    heading?: string
    description?: string
}

export const createElectionPartyTotalsPage = ({
    getAdTargeting,
    electionDefinition: { election, logo, meta },
    heading,
    description,
}: CreateElectionPartyTotalsPageArgs): PageType<TheWestSection> => {
    let collectionHeading

    if (election.type === 'federal') {
        heading ??= `${election.year} Federal Election Party Totals`
        description ??= `Party Totals for the ${heading}`
        collectionHeading = `Latest Federal Election Updates`
    } else {
        heading ??= `${
            election.year
        } ${election.state.toUpperCase()} State Election Party Totals`
        description ??= `Party Totals for the ${heading}`
        collectionHeading = `Latest ${election.state.toUpperCase()} Election Updates`
    }

    return {
        kind: 'page',
        heading,
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting: getAdTargeting(
            'home',
            'default',
            getElection2025Topic(election),
        ),
        pageMeta: {
            title: heading,
            description,
            meta: meta.partyTotalsSocialImage
                ? getSocialImageMeta(
                      meta.partyTotalsSocialImage,
                      '2400',
                      '1260',
                  )
                : undefined,
        },
        socialMeta: {
            title: heading,
            description,
        },
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    flex: {
                        default: {
                            flexDirection: 'column',
                            gap: calcRem(24),
                        },
                        sm: {
                            gap: calcRem(48),
                        },
                    },
                    verticalGutters: ['unset', 'xxl'],
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'breaking-news',
                                        props: {
                                            dataDefinitionArgs: {
                                                type: 'curation',
                                                name: 'breaking-news',
                                                offset: 0,
                                                pageSize: 1,
                                            },
                                            isElectionPageRoute: true,
                                            removeBottomMargin: true,
                                        },
                                    }),
                                    layout.component({
                                        type: 'election-navigation',
                                        props: {
                                            election,
                                            electionLogo: logo,
                                            activePage: 'party-totals',
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'athena',
                            props: {
                                containerWidth:
                                    metrics.thewest.siteMetrics
                                        .mainContentWidth,
                                elementType: 'div',
                                sidebarOptions: 'hiddenMobile',
                                hasHorizontalGutters: false,
                                hasVerticalPadding: false,
                            },
                            contentAreas: {
                                sidebar: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            stickyOffset: 350,
                                            fillContainer: true,
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'ad-unit',
                                                    props: {
                                                        noticePosition:
                                                            'above-center',
                                                        padding: [
                                                            0,
                                                            0,
                                                            metrics.thewest
                                                                .margins.md,
                                                            0,
                                                        ],
                                                        slot: {
                                                            id: 'sidebar-top',
                                                            size: 'desktopMrecHalfPage',
                                                            pageType: 'static',
                                                        },
                                                        adType: 'inline',
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'juliet',
                                                    props: {
                                                        cardType: {
                                                            type: 'landscape',
                                                            format: 'landscape-smallImage',
                                                        },
                                                        removeHorizontalGutters:
                                                            true,
                                                        kickerMode: 'hidden',
                                                        contentIsTangential:
                                                            true,
                                                        sectionHeader: {
                                                            heading:
                                                                collectionHeading,
                                                        },
                                                        dataDefinitionArgs: {
                                                            type: 'curation',
                                                            name: getElectionId(
                                                                election,
                                                            ),
                                                            pageSize: 4,
                                                            offset: 0,
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],

                                main: [
                                    layout.nestedComposition({
                                        type: 'component-overrider',
                                        props: {
                                            override: () =>
                                                theWestTextOnlySectionHeaderOverride,
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.component({
                                                    type: 'section-header',
                                                    props: {
                                                        heading: 'Party Totals',
                                                        isStandalonePage: true,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    // layout.component({
                                    //     type: 'party-totals',
                                    //     props: {}
                                    // })
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
