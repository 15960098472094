import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    ElectionDefinition,
    getElectionId,
    GetRouteAdTargeting,
    getSocialImageMeta,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getElection2025Topic } from './createElectionLandingPage'

type CreateElectionPageArgs = {
    heading: string
    description: string
    getAdTargeting: GetRouteAdTargeting
    electionDefinition: ElectionDefinition
}

export const createElectionElectoratePage = ({
    heading,
    description,
    getAdTargeting,
    electionDefinition: { election, logo, meta },
}: CreateElectionPageArgs): PageType<TheWestSection> => {
    let collectionHeading

    if (election.type === 'federal') {
        collectionHeading = `Latest Federal Election Updates`
    } else {
        collectionHeading = `Latest ${election.state.toUpperCase()} Election Updates`
    }

    return {
        kind: 'page',
        heading,
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting: getAdTargeting(
            'home',
            'default',
            getElection2025Topic(election),
        ),
        pageMeta: {
            description,
            meta: meta.electoratesSocialImage
                ? getSocialImageMeta(
                      meta.electoratesSocialImage,
                      '2400',
                      '1260',
                  )
                : undefined,
        },
        socialMeta: {
            description,
        },
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'election-navigation',
                            props: {
                                election,
                                electionLogo: logo,
                                activePage: 'electorate-results',
                                headingLevel: 'h2',
                            },
                        }),
                        layout.nestedComposition({
                            type: 'athena',
                            props: {
                                containerWidth:
                                    metrics.thewest.siteMetrics
                                        .mainContentWidth,
                                elementType: 'div',
                                sidebarOptions: 'hiddenMobile',
                                hasHorizontalGutters: false,
                            },
                            contentAreas: {
                                sidebar: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            stickyOffset: 350,
                                            fillContainer: true,
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'ad-unit',
                                                    props: {
                                                        noticePosition:
                                                            'above-center',
                                                        padding: [
                                                            0,
                                                            0,
                                                            metrics.thewest
                                                                .margins.md,
                                                            0,
                                                        ],
                                                        slot: {
                                                            id: 'sidebar-top',
                                                            size: 'desktopMrecHalfPage',
                                                            pageType: 'static',
                                                        },
                                                        adType: 'inline',
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'juliet',
                                                    props: {
                                                        cardType: {
                                                            type: 'landscape',
                                                            format: 'landscape-smallImage',
                                                        },
                                                        removeHorizontalGutters:
                                                            true,
                                                        kickerMode: 'hidden',
                                                        contentIsTangential:
                                                            true,
                                                        sectionHeader: {
                                                            heading:
                                                                collectionHeading,
                                                        },
                                                        dataDefinitionArgs: {
                                                            type: 'curation',
                                                            name: getElectionId(
                                                                election,
                                                            ),
                                                            pageSize: 4,
                                                            offset: 0,
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],

                                main: [
                                    layout.component({
                                        type: 'election-full-electorate-widget',
                                        props: {
                                            dataDefinitionArgs: {
                                                type: 'election',
                                                electionId: 632,
                                                pageSize: 1,
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
