import {
    createRegisterableComponentWithData,
    ElectionDataLoader,
} from '@news-mono/web-common'
import React from 'react'
import { TheSeatEmbedCollectionWidget } from './TheSeatEmbedCollectionWidget'

export const SeatEmbedWidgetCollectionRegistration =
    createRegisterableComponentWithData(
        'election-seat-embed-collection-widget',
        ElectionDataLoader,
        (props: {}, data, services) => {
            return (
                <TheSeatEmbedCollectionWidget
                    data={data}
                    imageBaseUrl={services.config.imageBaseUrl}
                />
            )
        },
    )
