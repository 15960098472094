import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    ElectionDefinition,
    getElectionId,
    GetRouteAdTargeting,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'

type CreateElectionPageArgs = {
    electionDefinition: ElectionDefinition
    getAdTargeting: GetRouteAdTargeting
}

export const createElectionWidgetTestPage = ({
    electionDefinition,
    getAdTargeting,
}: CreateElectionPageArgs): PageType<TheWestSection> => {
    return {
        kind: 'page',
        heading: 'Election Widget Test Page',
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting: getAdTargeting('home', 'default', 'home'),
        pageMeta: {
            description: 'Election Widget Test Page',
        },
        socialMeta: {
            description: 'Election Widget Test Page',
        },
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    fullWidth: true,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'election-navigation',
                            props: {
                                election: electionDefinition.election,
                                electionLogo: electionDefinition.logo,
                            },
                        }),
                        layout.component({
                            type: 'election-the-race-widget',
                            props: {
                                election: electionDefinition.election,
                                dataDefinitionArgs: {
                                    type: 'election',
                                    electionId: 632,
                                    pageSize: 1,
                                },
                            },
                        }),
                        layout.component({
                            type: 'election-seat-widget',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'election',
                                    electionId: 632,
                                    pageSize: 1, // Throwaway value, required to pass type check ¯\_(ツ)_/¯
                                },
                                electoratePageUrl: `/politics/${getElectionId(
                                    electionDefinition.election,
                                )}/electorates`,
                            },
                        }),
                        layout.component({
                            type: 'election-full-electorate-widget',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'election',
                                    electionId: 632,
                                    pageSize: 1, // Throwaway value, required to pass type check ¯\_(ツ)_/¯
                                },
                                electoratePageUrl: `/politics/${getElectionId(
                                    electionDefinition.election,
                                )}/electorates`,
                            },
                        }),
                        layout.component({
                            type: 'election-seat-embed-collection-widget',
                            props: {
                                dataDefinitionArgs: {
                                    type: 'election',
                                    electionId: 632,
                                    pageSize: 1,
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
