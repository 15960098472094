import React, {
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { SeatCard } from '../SeatCard/SeatCard'
import {
    getSeatsData,
    InterknowlogyData,
    searchSeatsOrCandidates,
    SeatData,
    transformAreaData,
} from '../data'
import { ElectionDataFetchError, ElectionTitle } from '../components'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import { TransformedData } from '@west-australian-newspapers/election-api-types'
import {
    TheSeatsWidgetChevron,
    TheSeatsWidgetContainer,
    TheSeatsWidgetHeaderLink,
    TheSeatsWidgetSeatGrid,
    TheSeatsNoResultsText,
    TheSeatsDivider,
} from './TheSeatsWidget.styled'
import {
    AllEvents,
    BaseClientConfig,
    DataLayerEventName,
    NavEvent,
    useQueryParams,
} from '@news-mono/web-common'
import { useImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { ElectionSearchInput } from '../components/ElectionSearch'

export interface SeatsWidgetProps {
    data: MaybeLoaded<TransformedData | undefined>
    onEvent?: (event: AllEvents) => void
    electoratePageUrl: string
    seatDisplayCount?: number
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    titleText?: string
    imageBaseUrl?: string
}

export const TheSeatsWidget = ({
    data,
    onEvent,
    electoratePageUrl,
    seatDisplayCount = 6,
    headingLevel,
    titleText = 'The Seats',
    imageBaseUrl,
}: SeatsWidgetProps): JSX.Element => {
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const { queryParams, pathname } = useQueryParams()

    const allSeatsData = useMemo(() => {
        if (!data.loaded || !data.result) return []
        const interknowlogyData = transformAreaData(
            data.result as InterknowlogyData,
        )
        return getSeatsData(interknowlogyData, imageBaseUrl, 64)
    }, [data, imageBaseUrl])

    // Memoized initial data to avoid pop-in on first load
    const initialData = useMemo(() => {
        const searchParams = queryParams.get('search')
        return searchParams
            ? searchSeatsOrCandidates(allSeatsData, searchParams).slice(
                  0,
                  seatDisplayCount,
              )
            : allSeatsData.slice(0, seatDisplayCount)
    }, [queryParams, allSeatsData, seatDisplayCount])

    const [seatData, setSeatData] = React.useState<SeatData[]>(initialData)

    useEffect(() => {
        if (!data.loaded || !data.result) {
            setSeatData([])
            return
        }

        const searchParams = queryParams.get('search')
        const filteredSeats = searchParams
            ? searchSeatsOrCandidates(allSeatsData, searchParams).slice(
                  0,
                  seatDisplayCount,
              )
            : allSeatsData.slice(0, seatDisplayCount)

        setSeatData(filteredSeats)
    }, [allSeatsData, queryParams, seatDisplayCount, data])

    const handleSearch = useCallback(
        (searchTerm: string) => {
            setSearchTerm(searchTerm)
            setSeatData(
                searchTerm
                    ? searchSeatsOrCandidates(allSeatsData, searchTerm).slice(
                          0,
                          seatDisplayCount,
                      )
                    : allSeatsData.slice(0, seatDisplayCount),
            )
        },
        [allSeatsData, seatDisplayCount],
    )

    // Loading State!
    if (!data.loaded) {
        return (
            <TheSeatsWidgetWrapper
                onEvent={onEvent}
                electoratePageUrl={electoratePageUrl}
            >
                <ElectionSearchInput
                    onEvent={onEvent}
                    queryParams={queryParams}
                    pathname={pathname}
                    onSearch={handleSearch}
                />
                <TheSeatsWidgetSeatGrid>
                    {Array(seatDisplayCount) // Render skeletons while loading
                        .fill(0)
                        .map((_, index) => (
                            <SeatCard key={index} isLoading />
                        ))}
                </TheSeatsWidgetSeatGrid>
            </TheSeatsWidgetWrapper>
        )
    }
    // Error State! (data has loaded, but there is no data)
    if (!data.result && data.loaded && !data.result) {
        return (
            <TheSeatsWidgetWrapper
                onEvent={onEvent}
                electoratePageUrl={electoratePageUrl}
            >
                <ElectionDataFetchError />
            </TheSeatsWidgetWrapper>
        )
    }

    // No Search Results State!
    if (searchTerm && seatData && seatData.length <= 0) {
        return (
            <TheSeatsWidgetWrapper
                onEvent={onEvent}
                electoratePageUrl={electoratePageUrl}
            >
                <ElectionSearchInput
                    onEvent={onEvent}
                    queryParams={queryParams}
                    pathname={pathname}
                    onSearch={handleSearch}
                />
                <TheSeatsNoResultsText>
                    No results for ‘{searchTerm}’
                </TheSeatsNoResultsText>
                <TheSeatsDivider />
            </TheSeatsWidgetWrapper>
        )
    }

    return (
        <TheSeatsWidgetWrapper
            onEvent={onEvent}
            electoratePageUrl={electoratePageUrl}
            headingLevel={headingLevel}
            titleText={titleText}
        >
            <ElectionSearchInput
                onEvent={onEvent}
                queryParams={queryParams}
                pathname={pathname}
                onSearch={handleSearch}
            />
            <TheSeatsWidgetSeatGrid>
                {seatData.map((seat) => (
                    <SeatCard
                        key={seat.seatId}
                        seat={seat}
                        onEvent={onEvent}
                        electoratePageUrl={electoratePageUrl}
                    />
                ))}
            </TheSeatsWidgetSeatGrid>
        </TheSeatsWidgetWrapper>
    )
}

const TheSeatsWidgetWrapper = ({
    children,
    onEvent,
    electoratePageUrl,
    headingLevel,
    titleText,
}: PropsWithChildren<{
    onEvent?: (event: NavEvent) => void
    electoratePageUrl: string
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    titleText?: string
}>) => {
    const impressionAvailableRef = useImpressionAvailable({
        loading: false,
        available: useCallback(() => {
            onEvent?.({
                type: DataLayerEventName.navAvailable,
                originator: 'ElectionTheSeatsWidget',
                payload: {
                    navName: 'ElectionSeats',
                },
            })
        }, [onEvent]),
    })

    const handleHeadingClick = useCallback(() => {
        onEvent?.({
            type: DataLayerEventName.navClicked,
            originator: 'ElectionTheSeatsWidget',
            payload: {
                navName: 'ElectionSeatsWidget',
                navLocation: 'OnPage',
                navText: 'The Seats',
                navLink: electoratePageUrl,
            },
        })
    }, [electoratePageUrl, onEvent])
    return (
        <TheSeatsWidgetContainer ref={impressionAvailableRef}>
            <TheSeatsWidgetHeaderLink
                to={electoratePageUrl}
                onClick={handleHeadingClick}
            >
                <ElectionTitle as={headingLevel || undefined}>
                    {titleText}
                </ElectionTitle>
                <TheSeatsWidgetChevron />
            </TheSeatsWidgetHeaderLink>
            {children}
        </TheSeatsWidgetContainer>
    )
}
