import { useTheme } from '@emotion/react'
import {
    AppState,
    AuthenticationState,
    ComponentServices,
    createCardClickedEvent,
    PublicationCardItem,
    toLinkWithHint,
    useFeature,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { FundingLabel } from '../../../advertising/FundingLabel/FundingLabel'
import { ResponsiveContainer } from '../../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../../content/Picture/ResponsivePictureLayouts'
import { VideoQueue } from '../../../templates/Publication/lib/get-video-queue'
import { ThemeOverrider } from '../../../themes/ThemeOverrider/ThemeOverrider'
import { isVideoClick } from '../../../__helpers/video-card-helpers'
import { ThemeOverrideTypes } from '../../../__styling/themes'
import { CardByline } from '../../CardByline/CardByline'
import { CommonCardProps } from '../../CardItem.Props'
import { CardText } from '../../CardText/CardText'
import {
    returnEntitledStatus,
    returnFundingTypeIfItemLoaded,
    returnImageWidthsFromContainerRatios,
    returnVideoQueue,
} from '../utils'
import {
    StyledContent,
    StyledHeroCard,
    StyledHeroCardLink,
    StyledHeroCardContainer,
    StyledHeroCardMedia,
    StyledMediaWrapper,
    StyledHeroCardLinkContainer,
} from './HeroCard.styled'

export interface HeroCardProps extends CommonCardProps, ResponsiveContainer {
    item: MaybeLoaded<PublicationCardItem>
    cardNumber: number
    cardServices?: ComponentServices
    adUnitPath: string | undefined
    mediaPosition?: 'left' | 'right'
    isLarge?: boolean
    hideBorder?: boolean
    overrideRestrictedVideoPlayback?: boolean
    themeOverride?: ThemeOverrideTypes
    showPublicationDate?: boolean
    cardPosition?: 'left' | 'right'
    isVrTest?: boolean
    bottomPadding?: number
}

export function HeroCard(props: HeroCardProps) {
    const {
        item,
        cardNumber,
        onEvent,
        containerWidthRatios,
        hideByline = false,
        cardServices,
        adUnitPath,
        mediaPosition = 'right',
        isLarge = true,
        hideBorder = false,
        overrideRestrictedVideoPlayback = false,
        showPublicationDate = false,
        cardPosition = 'left',
        isVrTest,
        bottomPadding = 0,
    } = props

    // isLarge determines the size of the play button on video cards, the underline on the card and the byline
    // isLarge = false && playButton = small, underline = hidden, byline=hidden
    const theme = useTheme()
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const storyClassification = true

    const fundingType = returnFundingTypeIfItemLoaded({
        item,
        storyClassification,
    })

    const imageWidths = returnImageWidthsFromContainerRatios({
        theme,
        containerWidthRatios,
    })

    const isEntitled = returnEntitledStatus({
        overrideRestrictedVideoPlayback,
        authentication,
    })

    const requiredAccessLevel = item.loaded && item.result.requiredAccess.level

    const isPremiumArticle =
        requiredAccessLevel === 'subscriber' ||
        requiredAccessLevel === 'registered'

    const linkClicked = (e: React.MouseEvent<HTMLElement>) => {
        if (!item.loaded) {
            e.preventDefault()
            return
        }

        if (isEntitled && isVideoClick(e.nativeEvent.target)) {
            e.preventDefault()
            return
        }

        onEvent(
            createCardClickedEvent(
                onEvent,
                item.result,
                'InternalHeroCard',
                'HeroCard',
                cardNumber,
                fundingType,
            ),
        )
    }

    let getVideoQueue: Promise<VideoQueue>

    if (cardServices) {
        getVideoQueue = returnVideoQueue({ item, cardServices })
    }

    const isLiveBlogMilestonesTeaserEnabled = useFeature(
        'live-blog-milestones-teaser',
    )

    // Let's see if we want to use the live blog styling!
    const isLiveBlog =
        item.loaded &&
        item.result.publicationKind === 'event' &&
        isLiveBlogMilestonesTeaserEnabled

    const CardContainerComponent = isLiveBlog
        ? StyledHeroCardContainer
        : StyledHeroCardLinkContainer

    const MediaComponent = (
        <StyledHeroCardMedia
            isLarge={false}
            item={item}
            fixedRatio="16:9"
            hasBackground={false}
            imageWidths={imageWidths}
            onEvent={onEvent}
            mediaMode={'default'}
            disableImageLazyLoad={true}
            imageLayout={ResponsivePictureLayout.ObjectFitContain}
            willPlayVideoInline={isPremiumArticle ? isEntitled : true}
            getVideoQueue={() => getVideoQueue}
            adUnitPath={adUnitPath}
        />
    )

    return (
        <ThemeOverrider override={props.themeOverride}>
            <CardContainerComponent
                onClick={linkClicked}
                cardPosition={cardPosition}
                to={toLinkWithHint(item)}
                isLoading={!item.loaded}
            >
                <StyledHeroCard
                    mediaPosition={mediaPosition}
                    isLarge={isLarge}
                    hideBorder={hideBorder}
                    bottomPadding={bottomPadding}
                >
                    <StyledContent
                        mediaPosition={mediaPosition}
                        cardPosition={cardPosition}
                    >
                        <CardText
                            item={item}
                            hasBackground={false}
                            kickerMode={{ size: 'large' }}
                            liveBlogConfiguration={
                                isLiveBlog
                                    ? {
                                          isEnabled: isLiveBlog,
                                          isVrTest: isVrTest,
                                          getLinkComponentWithChildren: (
                                              props,
                                          ) => {
                                              const linkTo =
                                                  props.to === ''
                                                      ? toLinkWithHint(item)
                                                      : props.to

                                              return (
                                                  <StyledHeroCardLink
                                                      to={linkTo}
                                                      key={props.key}
                                                  >
                                                      {props.children}
                                                  </StyledHeroCardLink>
                                              )
                                          },
                                      }
                                    : undefined
                            }
                            teaserMode={'visible'}
                            fontScale={1.75}
                            onEvent={onEvent}
                        />
                        {!hideByline && isLarge && (
                            <CardByline
                                item={item}
                                onEvent={onEvent}
                                showProfile={true}
                                showPublicationDate={showPublicationDate}
                            />
                        )}
                        {fundingType && (
                            <FundingLabel
                                hasBackground={false}
                                fundingType={fundingType}
                            />
                        )}
                    </StyledContent>
                    <StyledMediaWrapper
                        mediaPosition={mediaPosition ? mediaPosition : 'right'}
                        cardPosition={cardPosition}
                    >
                        {/* Wrap it in a linkable component! */}
                        {isLiveBlog ? (
                            <StyledHeroCardLink
                                to={toLinkWithHint(item)}
                                isLoading={!item.loaded}
                            >
                                {MediaComponent}
                            </StyledHeroCardLink>
                        ) : (
                            MediaComponent
                        )}
                    </StyledMediaWrapper>
                </StyledHeroCard>
            </CardContainerComponent>
        </ThemeOverrider>
    )
}

HeroCard.displayName = 'HeroCard'
