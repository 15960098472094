import styled from '@emotion/styled'
import { TextItem } from '../../../../../typography/TextItem/TextItem'
import { calcRem } from '../../../../../__styling/style-functions/calc-rem'
import { breakWords } from '../../../../../__styling/style-mixins'
import { colorsPN, metricsPN } from '@news-mono/design-tokens'
import { perthNowNGNTextMixin } from '../../../../../mixins'

export const StyledPNParagraph = styled(TextItem)(() => ({
    margin: calcRem(metricsPN.spacing['L'], 0),
    ...breakWords,
    ...perthNowNGNTextMixin.body.L.regular,

    '&:first-child': {
        marginTop: 0,
    },

    a: {
        ...perthNowNGNTextMixin.body.L.medium,
        textDecoration: 'none',
        color: colorsPN.text.brand,

        '&:hover': {
            color: colorsPN.text.brand,
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },

        '&:active, &:visited': {
            color: colorsPN.text.pressed,
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },

        '&:focus-visible': {
            outline: 'revert',
            color: colorsPN.text.brand,
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            borderRadius: `${metricsPN.radius['M']}px`,
        },
    },
}))
