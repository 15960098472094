import React from 'react'
import { useLocation } from 'react-router'
import { useFeature } from '@news-mono/web-common'
import {
    InterknowlogyData,
    SeatData,
    getSeatsData,
    transformAreaData,
} from '../data'
import { SeatCard } from '../SeatCard/SeatCard'
import {
    TheSeatEmbedWidgetContainer,
    TheSeatEmbedWidgetTitleContainer,
} from './TheSeatEmbedWidget.styled'
import { FederalElection2025Logo } from '../../../content/FederalElection2025/Logo'
import { ElectionTitleButton } from '../components'
import { WAStateElection2025Logo } from '../../../content/WAStateElection2025/Logo'
import { TransformedData } from '@west-australian-newspapers/election-api-types'
import { MaybeLoaded } from 'json-react-layouts-data-loader'

export interface TheSeatEmbedWidgetProps {
    data?: MaybeLoaded<TransformedData | undefined>
    mainRef?: React.RefObject<HTMLDivElement>
    fireClickEvent?: () => void
    electorateName?: string | null
    providedData?: SeatData
    isState?: boolean
    imageBaseUrl?: string
}

export const TheSeatEmbedWidget = ({
    data,
    mainRef,
    electorateName,
    isState,
    providedData,
    fireClickEvent,
    imageBaseUrl,
}: TheSeatEmbedWidgetProps) => {
    const electionEmbedsEnabled = useFeature('custom-election-embeds')

    const { search } = useLocation()
    const params = new URLSearchParams(search)

    // Check if the current URL contains 'state' or 'federal' to determine logo to use
    if (isState === undefined) {
        isState = search.includes('state')
    }

    // no name or data is provided, so assume it's not an embed, and we want to provide the actual
    // name from the provided url.
    if (!electorateName && !providedData) {
        electorateName = params.get('electorate')
    }

    const initialData =
        data && data.loaded
            ? getSeatsData(
                  transformAreaData(data?.result as InterknowlogyData),
                  imageBaseUrl,
                  64,
              ).find(
                  (seat) =>
                      seat.seatName.toLowerCase() ===
                      electorateName?.toLowerCase(),
              )
            : undefined

    // get the seat data for the provided electrorate name
    const [seatData, setSeatData] = React.useState<SeatData | undefined>(
        initialData ?? providedData,
    )

    // Migrate the loadApiSeatData into a callback to stop it from causing an unmounted component error
    const loadApiSeatData = React.useCallback(() => {
        if (data && data.loaded && data.result) {
            const interknowlogyData = transformAreaData(
                data.result as InterknowlogyData,
            )
            const seats = getSeatsData(interknowlogyData, imageBaseUrl, 64)

            const seat = seats.find(
                (seat) =>
                    seat.seatName.toLowerCase() ===
                    electorateName?.toLowerCase(),
            )
            setSeatData(seat)
        }
    }, [data, electorateName, imageBaseUrl])

    // Host the useEffect to call changes whenever one of the dependencies changes
    React.useEffect(() => {
        if (!electionEmbedsEnabled || seatData) {
            return
        }

        loadApiSeatData()
    }, [loadApiSeatData, electionEmbedsEnabled, seatData])

    const ElectionTitleLogo = isState
        ? WAStateElection2025Logo
        : FederalElection2025Logo

    const ViewElectoratesButton = ({
        desktopButton,
    }: {
        desktopButton: boolean
    }) => (
        <ElectionTitleButton
            to="todo"
            includeBorder={true}
            hideOnMobile={desktopButton}
            hideOnDesktop={!desktopButton}
            fireEvent={fireClickEvent}
            text={'View Electorates'}
        />
    )

    return electionEmbedsEnabled ? (
        <TheSeatEmbedWidgetContainer ref={mainRef}>
            <TheSeatEmbedWidgetTitleContainer>
                <ElectionTitleLogo
                    size="small"
                    fireClickEvent={fireClickEvent}
                />
                <ViewElectoratesButton desktopButton />
            </TheSeatEmbedWidgetTitleContainer>
            {seatData ? (
                <>
                    <SeatCard isEmbed seat={seatData} />
                </>
            ) : (
                <SeatCard isLoading isEmbed />
            )}
            <ViewElectoratesButton desktopButton={false} />
        </TheSeatEmbedWidgetContainer>
    ) : null
}
