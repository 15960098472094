import { TheWestSection } from '@news-mono/common'
import { StaticRoutes } from '@news-mono/web-common'
import { aflMatchCentreRoutes } from './afl-match-centre-routes'
import { courtInTheAct } from './court-in-the-act'
import { getEmailPreferenceRouteInfo } from './email-preference'
import { ePaper, ePaperSubscribe } from './ePaper'
import { theWestPageIsUnavailableRoute } from './errors'
import { get190YearsRouteInfo } from './features/190-years-the-west'
import { getBaliBombingsMyStoryRouteInfo } from './features/bali-bombings-my-story'
import { bestAustralianYarnRoute } from './features/best-australian-yarn'
import { bestAustralianYarn2022Route } from './features/best-australian-yarn/2022'
// import { bestAustralianYarn2024Route } from './features/best-australian-yarn/2024'
import { getBikieCodeRouteInfo } from './features/bikie-code'
import { getRetireReadyRouteInfo } from './features/retire-ready'
import { getVanishingCousinsRouteInfo } from './features/vanishing-cousins'
import { getBlakDeathsInCustodyRouteInfo } from './features/blak-deaths-in-custody'
import { getElectionFactCheckerRouteInfo } from './features/fact-checker'
import { getFoulPlayRouteInfo } from './features/foul-play'
import { getGenesisRouteInfo } from './features/genesis-ballet'
import { goMatildasMessagesRoute } from './features/go-matildas'
import { getLisaGovanRouteInfo } from './features/lisa-govan'
import { getMadMondayRouteInfo } from './features/mad-monday'
import { queenElizabethTributesRoute } from './features/queen-elizabeth-tribute'
import { getWestTonightRouteInfo } from './features/the-west-tonight'
import { getTradingUpRouteInfo } from './features/trading-up'
import { getUpLateRouteInfo } from './features/up-late'
import { getYourStoryOurStoryRouteInfo } from './features/your-story-our-story'
import { homepage } from './home'
import { pollieRater } from './pollieRater'
import { puzzles } from './puzzles'
import { regionalRoutes } from './regionals'
import { staticContentRoutes } from './static/static-content-routes'
import { getSwgRouteInfo } from './subscribe-with-google'
import { getTeachersOfferRouteInfo } from './teachers-offer'
import { customTopicRoutes } from './topic/custom-topic-routes'
import { liveScoresRoute } from './topic/custom/get-live-scores-route-info'
import { getUniversityOfferRouteInfo } from './university-offer'
import { upgradeAppRoute } from './upgrade-app-route-info'
import { westlive } from './west-live'
import { bestAustralianYarn2023Route } from './features/best-australian-yarn/2023'
import { getOlympicMedalTallyRouteInfo } from './olympics/medal-tally'
import { electionRoutes } from './elections/routes'

export const staticRoutes: StaticRoutes<TheWestSection> = {
    ...pollieRater,
    ...puzzles,
    ...staticContentRoutes,
    ...customTopicRoutes,
    ...homepage,
    ...regionalRoutes,
    ...westlive,
    ...ePaper,
    ...ePaperSubscribe,
    ...getYourStoryOurStoryRouteInfo,
    ...getLisaGovanRouteInfo,
    ...getEmailPreferenceRouteInfo,
    ...getSwgRouteInfo,
    ...getBlakDeathsInCustodyRouteInfo,
    ...getGenesisRouteInfo,
    ...upgradeAppRoute,
    ...getWestTonightRouteInfo,
    ...getUpLateRouteInfo,
    ...getTradingUpRouteInfo,
    ...liveScoresRoute,
    ...getElectionFactCheckerRouteInfo,
    ...theWestPageIsUnavailableRoute,
    ...getFoulPlayRouteInfo,
    ...queenElizabethTributesRoute,
    ...goMatildasMessagesRoute,
    ...bestAustralianYarnRoute,
    ...bestAustralianYarn2022Route,
    ...bestAustralianYarn2023Route,
    //...bestAustralianYarn2024Route,
    ...getBaliBombingsMyStoryRouteInfo,
    ...getBikieCodeRouteInfo,
    ...getRetireReadyRouteInfo,
    ...getVanishingCousinsRouteInfo,
    ...getTeachersOfferRouteInfo,
    ...getUniversityOfferRouteInfo,
    ...aflMatchCentreRoutes,
    ...getMadMondayRouteInfo,
    ...get190YearsRouteInfo,
    ...courtInTheAct,
    ...getOlympicMedalTallyRouteInfo,
    ...electionRoutes,
}
