/** @jsx jsx */
import { Interpolation, Theme, jsx } from '@emotion/react'
import {
    CardBreakpointRatios,
    CardItem,
    CollectionEvent,
    createCollectionAvailableEvent,
    FixedRatio,
    Product,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { CommonCardProps } from '../../cards/CardItem.Props'
import { MediaMode } from '../../cards/CardMedia/CardMedia'
import { TeaserMode } from '../../cards/CardText/CardTeaser'
import { KickerMode } from '../../cards/Kicker/Kicker'
import { Landscape } from '../../cards/Landscape/Landscape'
import {
    getLandscapeCardItemProps,
    LandscapeLayout,
} from '../../cards/Landscape/Landscape.layouts'
import { LandscapeStacked } from '../../cards/LandscapeStacked/LandscapeStacked'
import { LandscapeWide } from '../../cards/LandscapeWide/LandscapeWide'
import { Portrait } from '../../cards/Portrait/Portrait'
import {
    StyledGridItem,
    StyledHeader,
    StyledJuliet,
} from '../../collections/Juliet/Juliet.styled'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import {
    ResponsiveContainer,
    ResponsivePictureSizes,
} from '../../content/Picture/responsive'
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { OverrideThemeSection } from '../../__helpers/override-theme-section'
import { useProduct } from '../../__product/useProduct'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import { Section } from '../../__styling/settings/sections'
import { invertMaybeLoadedItems } from '../helpers/loading'
import { LandscapeNightly } from '../../cards/LandscapeNightly/LandscapeNightly'

export interface JulietProps extends CommonCardProps, ResponsiveContainer {
    className?: string
    hideByline?: boolean
    sectionHeader?: SectionHeaderProps
    section?: Section
    cardType: LandscapeLayout | 'LandscapeWide'
    cardHasBackground?: boolean
    showLargeCard?: boolean
    fixedRatios?: FixedRatio | FixedRatio[] | CardBreakpointRatios
    imageWidths?: ResponsivePictureSizes
    mediaMode?: MediaMode
    teaserMode?: TeaserMode
    kickerMode?: KickerMode
    removeHorizontalGutters?: boolean
    removeTopListPadding?: boolean
    timestamp?: TimestampType
    verticalSpacing?: keyof ThemeMargins
    onEvent: (event: CollectionEvent) => void
    disableImageLazyLoad?: boolean
    items: MaybeLoaded<CardItem[]>
    stickyOffset?: number
    expectedCards: number
    contentIsTangential?: boolean
    noHorizontalDividers?: boolean
    topicLevel?: 'parent'
    hideLastDivider?: boolean
    numbered?: boolean
    showTeaser?: boolean
    showBasicTeaser?: boolean
    numberOfTeasersToShow?: number
    condensedInfo?: boolean
}

export const Juliet: React.FC<JulietProps> = (props) => {
    const {
        className,
        sectionHeader,
        section,
        onEvent,
        showLargeCard,
        verticalSpacing,
        expectedCards,
        stickyOffset,
        fixedRatios,
        contentIsTangential: contentIstangential,
        noHorizontalDividers,
        hideLastDivider,
        removeTopListPadding,
        showTeaser,
        numberOfTeasersToShow,
    } = props
    const product = useProduct()
    const items = invertMaybeLoadedItems(props.items, expectedCards)

    const numItems = showLargeCard === true ? items.slice(1) : items

    const renderJuliet = () => (
        <ImpressionAvailable
            loading={!props.items.loaded}
            percentageVisibleThreshold={numItems.length >= 10 ? 5 : undefined} // PN sidebar is so long that it never fires an impression, unless we lower the threshold
            available={() => {
                if (!props.items.loaded) {
                    console.warn(
                        'Available should never be called when loading is true',
                    )
                    return
                }
                props.onEvent(
                    createCollectionAvailableEvent(
                        props.items.result,
                        'Juliet',
                        product,
                        props.onEvent,
                    ),
                )
            }}
        >
            {(ref) => (
                <StyledJuliet
                    as={contentIstangential ? 'aside' : 'div'}
                    ref={ref}
                    className={className}
                    verticalSpacing={verticalSpacing}
                    stickyOffset={stickyOffset}
                >
                    {sectionHeader && items && (
                        <StyledHeader>
                            <SectionHeader {...sectionHeader} />
                        </StyledHeader>
                    )}
                    {showLargeCard &&
                        items.slice(0, 1).map((item, index) => (
                            <StyledGridItem
                                key={index}
                                style={{
                                    marginBottom: metrics.perthnow.margins.md,
                                }}
                                noHorizontalDividers={
                                    noHorizontalDividers === true
                                }
                                hideLastDivider={hideLastDivider}
                            >
                                <CardSwitcher
                                    onEvent={props.onEvent}
                                    item={item}
                                    cardContext="juliet"
                                    cardNumber={1}
                                    publicationCard={(publicationItem) => (
                                        <Portrait
                                            item={publicationItem}
                                            fixedRatio={fixedRatios || ['16:9']}
                                            onEvent={onEvent}
                                            fontScale={2}
                                            cardNumber={1}
                                            mediaMode={'edgeToEdge'}
                                            hasPadding={true}
                                            teaserMode={
                                                props.teaserMode || 'visible'
                                            }
                                            hideByline={props.hideByline}
                                            kickerMode={props.kickerMode}
                                            containerWidthRatios={
                                                props.containerWidthRatios
                                            }
                                        />
                                    )}
                                />
                            </StyledGridItem>
                        ))}
                    {numItems.length > 0 &&
                        numItems.map((item, index) => {
                            const firstChildThemeInterpolation: Interpolation<Theme> =
                                removeTopListPadding
                                    ? {
                                          '&:first-child': {
                                              paddingTop: 0,
                                          },
                                      }
                                    : {}
                            const lastChildThemeInterpolation: Interpolation<Theme> =
                                {
                                    '&:last-child': {
                                        marginBottom: 0,
                                    },
                                }

                            const themeInterpolation: Interpolation<Theme> = {
                                ...firstChildThemeInterpolation,
                                ...lastChildThemeInterpolation,
                            }

                            if (props.cardType === 'LandscapeWide') {
                                return (
                                    <StyledGridItem
                                        key={index}
                                        css={themeInterpolation}
                                        noHorizontalDividers={
                                            noHorizontalDividers === true
                                        }
                                        hideLastDivider={hideLastDivider}
                                    >
                                        {renderLandscapeWide(
                                            item,
                                            index + (showLargeCard ? 2 : 1),
                                            props,
                                        )}
                                    </StyledGridItem>
                                )
                            } else {
                                return (
                                    <StyledGridItem
                                        key={index}
                                        css={themeInterpolation}
                                        noHorizontalDividers={
                                            noHorizontalDividers === true
                                        }
                                        hideLastDivider={hideLastDivider}
                                    >
                                        {determineLandscapeCardToRender(
                                            item,
                                            index + (showLargeCard ? 2 : 1),
                                            props.cardType,
                                            props,
                                            product,
                                            numberOfTeasersToShow,
                                            showTeaser,
                                        )}
                                    </StyledGridItem>
                                )
                            }
                        })}
                </StyledJuliet>
            )}
        </ImpressionAvailable>
    )

    return section ? (
        <OverrideThemeSection section={section}>
            {renderJuliet()}
        </OverrideThemeSection>
    ) : (
        renderJuliet()
    )
}
Juliet.displayName = 'Juliet'

function renderLandscapeWide(
    item: MaybeLoaded<CardItem>,
    cardNumber: number,
    props: JulietProps,
) {
    return (
        <CardSwitcher
            onEvent={props.onEvent}
            item={item}
            cardNumber={cardNumber}
            cardContext="juliet-landscapewide"
            publicationCard={(publicationItem) => (
                <LandscapeWide
                    item={publicationItem}
                    onEvent={props.onEvent}
                    timestamp={props.timestamp}
                    fixedRatio={props.fixedRatios || ['4:3', '16:9']}
                    disableImageLazyLoad={props.disableImageLazyLoad}
                    cardNumber={cardNumber}
                    mediaMode={props.mediaMode}
                    teaserMode={props.teaserMode || 'visible'}
                />
            )}
        />
    )
}

export function renderLandscapeNightly(
    item: MaybeLoaded<CardItem>,
    cardNumber: number,
    layout: LandscapeLayout,
    props: JulietProps,
    numberOfTeasersToShow = -1,
    showTeaser?: boolean,
) {
    const onEvent = props.onEvent
    const renderTeaser = showTeaser && numberOfTeasersToShow >= cardNumber

    return (
        <CardSwitcher
            onEvent={onEvent}
            item={item}
            cardNumber={cardNumber}
            cardContext="juliet-landscapenightly"
            publicationCard={(publicationItem) => (
                <LandscapeNightly
                    {...getLandscapeCardItemProps(
                        publicationItem,
                        layout,
                        { onEvent },
                        cardNumber,
                    )}
                    imageWidths={props.imageWidths}
                    hideByline={props.hideByline}
                    topicLevel={props.topicLevel}
                    disableImageLazyLoad={props.disableImageLazyLoad}
                    imagePosition={props.numbered ? 'right' : 'left'}
                    contentPosition={props.numbered ? 'top' : 'center'}
                    displayNumber={props.numbered}
                    renderBasicTeaser={renderTeaser}
                    condensedInfo={props.condensedInfo}
                />
            )}
        />
    )
}

export function renderLandscape(
    item: MaybeLoaded<CardItem>,
    cardNumber: number,
    layout: LandscapeLayout,
    props: JulietProps,
) {
    const onEvent = props.onEvent

    return (
        <CardSwitcher
            onEvent={onEvent}
            item={item}
            cardNumber={cardNumber}
            cardContext="juliet-landscape"
            publicationCard={(publicationItem) => (
                <Landscape
                    {...getLandscapeCardItemProps(
                        publicationItem,
                        layout,
                        { onEvent },
                        cardNumber,
                    )}
                    imageWidths={props.imageWidths}
                    hideByline={props.hideByline}
                    fixedRatio={props.fixedRatios || ['4:3', '16:9']}
                    disableImageLazyLoad={props.disableImageLazyLoad}
                />
            )}
        />
    )
}

function renderLandscapeStacked(
    item: MaybeLoaded<CardItem>,
    cardNumber: number,
    props: JulietProps,
) {
    return (
        <CardSwitcher
            onEvent={props.onEvent}
            item={item}
            cardNumber={cardNumber}
            cardContext="juliet-landscapestacked"
            publicationCard={(publicationItem) => (
                <LandscapeStacked
                    item={publicationItem}
                    onEvent={props.onEvent}
                    disableImageLazyLoad={props.disableImageLazyLoad}
                    teaserMode={props.teaserMode || 'visible'}
                    kickerMode={props.kickerMode}
                    hideByline={props.hideByline}
                    fixedRatio={props.fixedRatios || '16:9'}
                    cardNumber={cardNumber}
                    mediaMode={props.mediaMode}
                />
            )}
        />
    )
}

function determineLandscapeCardToRender(
    item: MaybeLoaded<CardItem>,
    cardNumber: number,
    cardType: LandscapeLayout,
    props: JulietProps,
    product: Product,
    numberOfTeasersToShow?: number,
    showTeaser?: boolean,
) {
    if (product === 'sevennews') {
        return renderLandscapeStacked(item, cardNumber, props)
    }

    if (product === 'thenightly') {
        return renderLandscapeNightly(
            item,
            cardNumber,
            cardType,
            props,
            numberOfTeasersToShow,
            showTeaser,
        )
    }
    return renderLandscape(item, cardNumber, cardType, props)
}
